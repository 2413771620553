import {
  Checkbox,
  Typography,
  FormControlLabel,
  Grid,
  Link,
  Card,
} from "@mui/material";
import { useState, useEffect } from "react";
import { isError, validateEmail, validateFields, validateName, validatePassword, validatePhone } from "../../utils/validation-util";
import { coreStyles } from "../../theme/style";
import Logo from "../component/logo";
import Status from "../component/status";
import { logOut, navigate } from "../../utils/route-util";
import { AppRoutes } from "../../router/routes";
import EasyTextEdit from "../component/text-edit";
import { executePostRequest } from "../../utils/http-util";
import { setTitle } from "../../utils/util";
import LoadingButton from "../component/button";

const userRef = {
  firstName: "",
  lastName: "",
  emailAddress: "",
  phoneNumber: "",
  password: "",
  role: "MERCHANT",
  mfaEnabled: false,
  status: "CREATED",
  acceptedTerms: false
};

const validationRef = {
  firstName: { error: true, touched: false },
  lastName: { error: true, touched: false },
  emailAddress: { error: true, touched: false },
  phoneNumber: { error: true, touched: false },
  password: { error: true, touched: false },
};

const labelRef = "Create an account";

const AccountRegister = () => {
  const [user, setUser] = useState(userRef);
  const [validation, setValidation] = useState(validationRef);
  const [validated, setValidated] = useState(false);
  const appStyle = coreStyles();
  const [onProcessing, setOnProcessing] = useState(false);
  const [btnLabel, setLabel] = useState(labelRef);
  const [level, setLevel] = useState("");
  const [status, setStatus] = useState({ error: false, message: null });

  const handleSubmit = (event) => {
    event.preventDefault();
    executePostRequest("users", user, (loading) => {
      setLabel("");
      setOnProcessing(loading);
    }).then(result => {
      setLabel(labelRef);
      setStatus({
        ...{
          error: result.error,
          message: !result.error ? "Account created successfully" : result.message,
        },
      })
    })
  };

  const onValueChange = (value, tag) => {
    user[tag] = value;
    if (tag === "password") {
      const strn = validatePassword(value);
      validation.password = { error: strn.level < 75, touched: true };
      setLevel(strn);
    }

    if (tag === "firstName") {
      validation.firstName = { error: !validateName(value), touched: true };
    }
    if (tag === "lastName") {
      validation.lastName = { error: !validateName(value), touched: true };
    }

    if (tag.includes("email")) {
      validation.emailAddress = { error: !validateEmail(value), touched: true };
    }

    if (tag.includes("phone")) {
      validation.phoneNumber = { error: !validatePhone(value), touched: true };
    }
    setUser({ ...user });
    setValidation({ ...validation });
    setValidated(validateFields(validation) && user.acceptedTerms);
  };

  useEffect(() => {
    setTitle("Register an account, Sign up now");
    logOut(false);
    // eslint-disable-next-line
  }, []);

  return (
    <Grid
      container
      className={appStyle.container}
      justifyContent="center"
      elevation={4}
      alignItems="center"
    >
      <Grid item textAlign="center" xs={12}>
        <Logo image="easypay-logo-white.svg" />
      </Grid>
      <Grid item xs={12} md={7}>
        <Card className={appStyle.loginCard}>
          <Typography
            variant="h2"
            color="primary.darker"
            align="center"
            mb={4}
            className={appStyle.title}
          >
            Create account
          </Typography>
          <Typography
            align="center"
            variant="body1"
            mb={4}
            className={appStyle.sub_title}
          >
            You're moments away from launching a blazing easy payment solution.
          </Typography>

          <Grid container spacing={2}>
            {status && status.message && (
              <Grid item xs={12}>
                <Status
                  error={status.error}
                  timeout={5000}
                  message={status.message}
                  onClose={() => {
                    if (status.error) {
                      setStatus({ ...{ message: null } });
                    } else {
                      navigate(AppRoutes.auth.login, true);
                    }
                  }}
                />
              </Grid>
            )}
            <Grid item xs={12} md={6}>
              <EasyTextEdit
                placeholder="First name"
                type="text"
                id="firstName"
                errorText="Invalid first name"
                error={isError(validation.firstName)}
                disabled={onProcessing}
                value={user.firstName}
                icon="person"
                onChange={onValueChange}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <EasyTextEdit
                placeholder="Last name"
                type="text"
                id="lastName"
                errorText="Invalid last name"
                error={isError(validation.lastName)}
                disabled={onProcessing}
                value={user.lastName}
                icon="person"
                onChange={onValueChange}
              />
            </Grid>

            <Grid item xs={12} md={12}>
              <EasyTextEdit
                placeholder="Email address"
                type="email"
                id="emailAddress"
                errorText="Invalid email address"
                error={isError(validation.emailAddress)}
                disabled={onProcessing}
                value={user.emailAddress}
                icon="alternate_email"
                onChange={onValueChange}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <EasyTextEdit
                placeholder="Phone number"
                type="phone"
                id="phoneNumber"
                errorText="Invalid phone number"
                error={isError(validation.phoneNumber)}
                disabled={onProcessing}
                value={user.phoneNumber}
                icon="phone"
                onChange={onValueChange}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <EasyTextEdit
                placeholder="Password"
                type="password"
                id="password"
                errorText={`Password is ${level.label}`}
                error={isError(validation.password)}
                disabled={onProcessing}
                value={user.password}
                icon="password"
                password={true}
                onChange={onValueChange}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <FormControlLabel
              sx={{mt: 1}}
                checked={user.mfaEnabled}
                onChange={(event) =>
                  onValueChange(event.target.checked, "mfaEnabled")
                }
                control={<Checkbox />}
                label={
                  <Typography className={appStyle.inputs} variant="body2">
                    Enable Multifactor Authentication <strong>(MFA)</strong>
                  </Typography>
                }
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <FormControlLabel
                checked={user.acceptedTerms}
                onChange={(event) =>
                  onValueChange(event.target.checked, "acceptedTerms")
                }
                control={<Checkbox />}
                label={
                  <p align="center">
                    <Typography
                      component="span"
                      variant="body2"
                      className={appStyle.input_icons}
                    >
                      I agree to the
                    </Typography>
                    <Link
                      href={AppRoutes.legal.index}
                      variant="body2"
                      className={appStyle.terms_link}
                    >
                      &nbsp;&nbsp;Terms of Service
                    </Link>
                  </p>
                }
              />
            </Grid>

            <Grid container justifyContent="center">
              <Grid item xs={12} md={8} sx={{mt: 2}}>
                <LoadingButton
                  onClick={handleSubmit}
                  loading={onProcessing}
                  disabled={!(!onProcessing && validated) || (!status.error && status.message)}
                  label={btnLabel}
                />
              </Grid>
            </Grid>

            <Grid container justifyContent="center">
              <Grid item mt={4} xs={12}>
                <p align="center">
                  <Typography
                    component="span"
                    variant="body2"
                    className={appStyle.link_span}
                  >
                    Already a member?
                  </Typography>
                  <Link
                    href={AppRoutes.auth.login}
                    variant="body2"
                    className={appStyle.links}
                  >
                    &nbsp;&nbsp;Login
                  </Link>
                </p>
              </Grid>
            </Grid>
            
          </Grid>
        </Card>
      </Grid>
    </Grid>
  );
};

export default AccountRegister;
