import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  isEnter: false,
};
const keyPressSlice = createSlice({
  name: "enter",
  initialState,
  reducers: {
    keyPress: (state, action) => {
      state.isEnter = action.payload;
    }
  },
});
export const { keyPress } = keyPressSlice.actions;
export default keyPressSlice.reducer;