import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import { Toolbar, Typography, OutlinedInput, InputAdornment, Icon } from '@mui/material';
import MoreMenu from './more';

const RootStyle = styled(Toolbar)(({ theme }) => ({
  height: 96,
  display: "flex",
  justifyContent: "space-between",
  padding: theme.spacing(0, 1, 0, 3),
}));

const SearchStyle = styled(OutlinedInput)(({ theme }) => ({
  width: 600,
  transition: theme.transitions.create(["box-shadow", "width"], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter,
  }),
  "&.Mui-focused": { width: 700, boxShadow: theme.customShadows.z8 },
  "& fieldset": {
    borderWidth: `1px !important`,
    borderColor: `${theme.palette.grey[500_32]} !important`,
  },
}));

ListToolbar.propTypes = {
  selected: PropTypes.array,
  filterName: PropTypes.string,
  label: PropTypes.string,
  selectionOptions: PropTypes.array,
  onOptionSelection: PropTypes.func.isRequired,
  filterOptions: PropTypes.array,
  onFilterName: PropTypes.func,
};

export default function ListToolbar({
  selected = [], 
  filterName,
  label,
  selectionOptions,
  onOptionSelection,
  filterOptions,
  onFilterName,
}) {
  const selection = selected.length > 0 && selectionOptions;
  return (
    <RootStyle
      sx={{
        ...(selected.length > 0 && {
          color: "primary.main",
          bgcolor: "primary.lighter",
        }),
      }}
    >
      {selected.length > 0 ? (
        <Typography component="strong" variant="title1">
          {selected.length} selected
        </Typography>
      ) : (
        <SearchStyle
          value={filterName}
          onChange={onFilterName}
          placeholder={"Search " + label + "..."}
          startAdornment={
            <InputAdornment position="start">
              <Icon
                children="search"
                sx={{ color: "text.disabled", width: 20, height: 20 }}
              />
            </InputAdornment>
          }
        />
      )}
      {(selectionOptions.length > 0 || filterOptions.length > 0) && (
        <MoreMenu
          selected={selected}
          icon={selection ? "more_vert" : "filter_list"}
          onItemSelected={() => onOptionSelection()}
          options={selection ? selectionOptions : filterOptions}
        />
      )}
    </RootStyle>
  );
}