import {
  Typography,
  Grid,
  Card,
  CircularProgress,
  Box,
  Divider,
  Tab,
  Tabs,
  Icon,
} from "@mui/material";
import { useEffect, useState } from "react";
import { coreStyles } from "../../theme/style";
import Logo from "../component/logo";
import { getParams, logOut, preventIfNoValidLinkToken } from "../../utils/route-util";
import { executeGetRequest, executePostRequest } from "../../utils/http-util";
import { styled } from '@mui/material/styles'
import EasyTextEdit from "../component/text-edit";
import { isError, validateFields, validatePhone } from "../../utils/validation-util";
import LoadingButton from "../component/button";
import { formatCurrency, setTitle } from "../../utils/util";
import useCountDown from 'react-countdown-hook';
import { millisToMinutesAndSeconds } from "../../utils/date-util";
import { useSelector } from "react-redux";
import { updateAddSessionValue } from "../../utils/session-util";

const AntTabs = styled(Tabs)({
  borderBottom: '1px solid #e8e8e8',
  '& .MuiTabs-indicator': {},
});

const AntTab = styled((props) => <Tab disableRipple {...props} />)(({ theme }) => ({
  textTransform: 'none',
  minWidth: 0,
  [theme.breakpoints.up('sm')]: {
    minWidth: 0,
  },
  fontWeight: theme.typography.fontWeightRegular,
  fontSize: "1.1em",
  paddingLeft: "0px !important",
  color: '#2065D1',
  fontFamily: [
    '-apple-system',
    'BlinkMacSystemFont',
    '"Segoe UI"',
    'Roboto',
    '"Helvetica Neue"',
    'Arial',
    'sans-serif',
    '"Apple Color Emoji"',
    '"Segoe UI Emoji"',
    '"Segoe UI Symbol"',
  ].join(','),
  '&:hover': {
    color: '#061B64',
    opacity: .8,
  },
  '&.Mui-selected': {
    color: '#061B64',
    fontWeight: theme.typography.fontWeightMedium,
  },
  '&.Mui-focusVisible': {
    backgroundColor: '#d1eaff',
  },
}));

const billRef = {
  accountNumber: "",
};

const validationRef = {
  accountNumber: { error: true, touched: false }
};

const responseMessage = {
  COMPLETED: { error: false, title: "Payment Successful", icon: "done_all", message: "Thank you! Your payment has been processed successfully, You will receive a confirmation SMS shortly." },
  FAILED: { error: true, title: "Payment Failed", icon: "error", message: "We're sorry, but your payment could not be processed at this time, try gain next time" },
  ERROR: { error: true ,tittle: "Invalid Payment", icon: "block", message: "We're sorry, but the payment details you entered are invalid" }
}


export const PaymentLink = () => {
  const appStyle = coreStyles();
  const { token } = getParams();
  const [selectedTab, setSelectedTab] = useState("MOBILE");
  const [validated, setValidated] = useState(false);
  const [bill, setBill] = useState(billRef);
  const [linkInfo, setLinkInfo] = useState({});
  const [validation, setValidation] = useState(validationRef);
  const [processing, setProcessing] = useState({ infoLoading: true, payLoading: false, statusLoading: false });
  const [timeLeft, { start, pause, reset }] = useCountDown(180000, 1000);
  const [btnLabel] = useState("Pay Now");
  const { data } = useSelector((state) => state.lavinMq);
  const [transaction, setTransaction] = useState({});
  const [status, setStatus] = useState({ message: null, icon: null, error: false });

  useEffect(() => {
    if (processing.payLoading) {
      window.addEventListener("beforeunload", preventReload);
      return () => {
        window.removeEventListener("beforeunload", preventReload);
      };
    }
  }, [processing.payLoading]);

  const preventReload = (e) => {
    e.preventDefault();
    e.returnValue = "";
  };


  const fetchPaymentDetails = () => {
    executeGetRequest(`payments/link`, (loading) => {
      setProcessing({ ...{ infoLoading: loading } });
    }, { "x-link-token": token }).then(result => {
      const _status = result.error ? "ERROR" : result.status;
      const statusProps = responseMessage[_status];
      const showInfo = ["INITIATED", "PENDING", "LOGGED"].includes(result.status);
      if (showInfo) {
        setTitle(`Pay ${result.business}`);
        setSelectedTab(result.show[0].key)
        onValueChange(result.accountNumber, "accountNumber")
        setLinkInfo(result);
      } else {
        if (statusProps) setStatus({ ...statusProps });
      }
    })
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    setProcessing({ ...{ statusLoading: true } })
    start();
    executePostRequest("payments/link", bill,
      (loading) => setProcessing({ ...{ payLoading: loading, statusLoading: true } }),
      { "x-link-token": token }
    ).then(result => {
      if (result.error) {
        reset();
        setProcessing({ ...{ statusLoading: false } });
      } else {
        setTransaction(result.data);
        updateAddSessionValue("trxn", result.data);
      }
    })
  };

  useEffect(() => {
    if (data.transactionId === transaction.tramsactionId) {
      pause();
      setStatus({ ...responseMessage[data.status] });
      setTimeout(() => logOut(), 3000);
    }
    // eslint-disable-next-line
  }, [data]);


  useEffect(() => {
    setTitle("Pay links");
    preventIfNoValidLinkToken();
    fetchPaymentDetails();
    logOut(false);
    // eslint-disable-next-line
  }, []);

  const onValueChange = (value, tag) => {
    bill[tag] = value;

    if (tag.includes("account")) {
      validation.accountNumber = { error: !validatePhone(value), touched: true };
    }

    setBill({ ...bill });
    setValidation({ ...validation });
    setValidated(validateFields(validation));
  }

  return (
    <Grid
      container
      className={appStyle.container}
      justifyContent="center"
      elevation={4}
      alignItems="center"
    >
      <Grid item textAlign="center" xs={12}>
        <Logo image="easypay-logo-white.svg" />
      </Grid>
      <Grid item xs={12} md={status.message ? 6 : processing.infoLoading ? 7 : 9}>
        {Object.keys(linkInfo).length && !status.message && (<Card className={appStyle.loginCard}>
          <Typography
            variant="h2"
            color="primary.darker"
            align="center"
            mb={4}
            className={appStyle.title}
          >
            Complete Your Payment
          </Typography>
          <Typography
            align="center"
            variant="body1"
            mb={6}
            className={appStyle.subTitle}
          >
            Choose your preferred secure payment method to complete this order.
          </Typography>

          <Grid container mt={5} columnSpacing={8} rowSpacing={4}>
            <Grid item xs={12} md={6}>
              <Grid container>
                <Grid item xs={12}>
                  <Typography
                    variant="h5"
                    color="primary.darker"
                    align="left"
                    mb={4}
                    mt={2}
                    className={appStyle.title}
                  >
                    Payment Summary
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Grid display="flex" justifyContent="space-between">
                    <Typography
                      variant="body"
                      align="left"
                      mb={2}
                      mt={1}
                    >
                      Total amount
                    </Typography>

                    <Typography
                      variant="h4"
                      align="right"
                      mb={2}
                    >
                      {formatCurrency(linkInfo.amount)}
                    </Typography>
                  </Grid>
                  <Divider sx={{ mb: 4 }} />
                  <Grid display="flex" justifyContent="space-between">
                    <Typography
                      variant="body"
                      align="left"
                      mb={3}
                    >
                      Business
                    </Typography>

                    <Typography
                      variant="body"
                      align="right"
                      mb={3}
                    >
                      {linkInfo.business}
                    </Typography>
                  </Grid>


                  <Grid display="flex" justifyContent="space-between">
                    <Typography
                      variant="body"
                      align="left"
                      mb={3}
                    >
                      Transaction Ref
                    </Typography>

                    <Typography
                      variant="body"
                      align="right"
                      mb={3}
                    >
                      {linkInfo.transactionId}
                    </Typography>
                  </Grid>

                  <Grid display="flex" justifyContent="space-between">
                    <Typography
                      variant="body"
                      align="left"
                      mb={4}
                    >
                      Currency
                    </Typography>

                    <Typography
                      variant="body"
                      align="right"
                      mb={4}
                    >
                      {linkInfo.currency}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12} md={6}>
              <Box sx={{ width: '100%' }}>
                <Box sx={{ bgcolor: '#fff' }}>
                  <AntTabs value={selectedTab} onChange={(_, selected) => {
                    setSelectedTab(selected);
                    onValueChange(selected)
                  }} aria-label="">
                    {linkInfo.show.map(option => {
                      return (
                        <AntTab label={option.title} value={option.key} />
                      )
                    })}
                  </AntTabs>
                  <Box sx={{ p: 3 }} />
                </Box>
                <Box>
                  {selectedTab === "MOBILE" && (
                    <Grid container spacing={2}>
                      <Grid item xs={12} >
                        <EasyTextEdit
                          placeholder="Phone number"
                          type="phone"
                          id="accountNumber"
                          errorText="Invalid phone number"
                          error={isError(validation.accountNumber)}
                          disabled={processing.payLoading}
                          value={bill.accountNumber}
                          icon="phone"
                          onChange={onValueChange}
                        />

                        <Typography
                          align="center"
                          variant="subtitle2"
                          component={"p"}
                          color={"text.disabled"}
                          sx={{ pt: 3 }}
                        >
                          Keep your phone unclocked while awaiting for a PIN request
                        </Typography>
                      </Grid>

                    </Grid>
                  )}

                  <Grid item xs={12} mt={3}>

                    {!processing.statusLoading && (<LoadingButton
                      onClick={handleSubmit}
                      loading={processing.payLoading}
                      disabled={!(!processing.payLoading && validated)}
                      label={processing.payLoading ? "" : btnLabel}
                    />)}
                    {timeLeft > 0 && processing.statusLoading && (<Typography
                      align="center"
                      variant="h4"
                      component={"p"}
                      color={"primary"}
                      sx={{ pt: 3 }}
                    >
                      {millisToMinutesAndSeconds(timeLeft)}
                    </Typography>)}

                  </Grid>
                </Box>
              </Box>
            </Grid>

          </Grid>

        </Card>)}

        {!Object.keys(linkInfo).length && !status.message && (<Card className={appStyle.loginCard}>
          <Box
            noValidate
            sx={{ mt: 1, mb: 6, py: 1 }}>

            <Grid container justifyContent="center">
              <Grid
                item
                mb={3}>
                <CircularProgress
                  color="primary"
                  size="7rem"
                />

              </Grid>

              <Grid item
                mt={6}
                mb={5}
                xs={12}>
                <Typography
                  align="center"
                  variant="h3"
                  className={appStyle.textPadding}
                >
                  Fetching payment info...
                  <br />
                </Typography>
              </Grid>
            </Grid>
          </Box>
        </Card>)}

        {status.message && (
          <Card className={appStyle.loginCard}>
            <Grid container justifyContent="center">
              <Grid
                item
                mt={6}
                mb={5}
                xs={12}
                container justifyContent="center"
                className={status.message ? appStyle.show : appStyle.hide}
              >
                <Icon
                  children={status.icon}
                  color={status.error ? "error" : "success"}
                  className={appStyle.icon_standalone}
                />

              </Grid>

              <Grid conatiner justifyContent={"center"}
                xs={12}
                mb={3}
                >
                <Typography
                  align="center"
                  variant="h3"
                  className={appStyle.textPadding}
                >
                  {status.title}
                  <br />
                </Typography>
              </Grid>


              <Grid item
                xs={12}
                mb={6}>
                <p
                  align="center"
                  variant="body"
                  className={appStyle.statusMessage}
                >
                  {status.message}
                  <br />
                </p>
              </Grid>

            </Grid>

          </Card>
        )}
      </Grid>
    </Grid>
  );
};
