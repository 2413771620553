import {
  copyText,
  maskString,
  showSnackMessage,
  Statuses,
  setTitle,
  getStatus,
} from "../../utils/util";
import { useState, useEffect } from "react";
import { executeDeleteRequest, executeGetRequest, executePatchRequest } from "../../utils/http-util";
import { userRoleCheck } from "../../utils/session-util";
import { navigate, preventIfNoSession } from "../../utils/route-util";
import { formatDate } from "../../utils/date-util";
import { AppRoutes } from "../../router/routes";
import DataList from "../component/@list/list";

const TABLE_HEAD = [
  { id: "createdAt", label: "Created On", alignRight: false },
  { id: "name", label: "App Name", alignRight: false },
  { id: "tillNumber", label: "TILL", alignRight: false },
  { id: "owner", label: "Created By", alignRight: false },
  { id: "whiteListedIp", label: "Whitelisted IP", alignRight: false },
  { id: "api_key", label: "API Key", alignRight: false },
  { id: "revenueShare", label: "Rev Share", alignRight: false },
  { id: "active", label: "Status", alignRight: false },
  { id: "_" },
];

const AppList = () => {
  const roleCheck = userRoleCheck();
  const [dataList, setDataList] = useState([]);
  const [loading, onLoading] = useState(false);
  const [filterKey, setFilterkey] = useState("createdAt");

  const appOperations = [
    {
      icon: "delete",
      value: "delete",
      label: "Delete",
      onClick: (_, selected) => {
        onLoading(true);
        Promise.all(selected.map(uuid => executeDeleteRequest(`apps/${uuid}`)))
          .then(result => executeFetch())
      }
    },
    {
      icon: "edit",
      value: "edit",
      label: "Edit",
      onClick: (_value, selected) => navigate(`${AppRoutes.dashboard.appEdit}?uuid=${selected.join("")}`),
    },
    {
      icon: "lock_clock",
      value: "suspend",
      label: "Suspend",
      onClick: (_, selected) => {
        onLoading(true);
        Promise.all(selected.map(uuid => executePatchRequest(`apps/${uuid}`, { status: Statuses.SUSPENDED })))
          .then(result => executeFetch())
      }

    },
    {
      icon: "done_all",
      value: "approve",
      label: "Approve",
      onClick: (_, selected) => {
        onLoading(true);
        Promise.all(selected.map(uuid => executePatchRequest(`apps/${uuid}`, { status: Statuses.APPROVED })))
          .then(result => executeFetch())
      }
    },
    /*{
      icon: "paid",
      value: "revenue",
      label: "Revenue",
      onClick: (value, selected) => {
        navigate(
          `${AppRoutes.apps}/${AppRoutes.appRevenue}?id=${selected.join("")}`,
          true
        );
      },
    }, */
    {
      icon: "content_copy",
      value: "copy",
      label: "Copy API Key",
      onClick: (_, selected) => {
        copyText(
          dataList.filter((item) => item.uuid === selected[0])[0].auth.secret
        );
        showSnackMessage("API Key was copied to a clipboard ");
      },
    },
    {
      icon: "key",
      value: "generate",
      label: "Generate API key",
      onClick: (_, selected) => {
        onLoading(true);
        Promise.all(selected.map(uuid => executePatchRequest(`apps/${uuid}/key`, { status: Statuses.SUSPENDED })))
          .then(result => executeFetch())
      },
    },
  ];

  const filterOptions = [
    { value: "name", label: "App name", onClick: setFilterkey },
    { value: "tillNumber", label: "TILL", onClick: setFilterkey },
    { value: "createdAt", label: "Created On", onClick: setFilterkey },
  ];

  const executeFetch = () => {
    executeGetRequest("apps",
      (loading) => onLoading(loading)
    ).then(result => setDataList(result.filter(app => app.auth)));
  };

  useEffect(() => {
    preventIfNoSession();
    executeFetch(true);
    setTitle("Applications")
    // eslint-disable-next-line
  }, []);

  return (
    <DataList
      sectionTitle="Apps"
      createNewLabel={ roleCheck.isMerchant ? "Create an app" : null }
      data={dataList}
      primaryKey="uuid"
      filterColumn={filterKey}
      emptyIcon="apps"
      avatarKey="name"
      loading={loading}
      onCreateNewItem={() =>
        navigate(`${AppRoutes.dashboard.appEdit}`, true)
      }
      headers={TABLE_HEAD.filter(header => roleCheck.isMerchant ? header.id !== "owner": true)}
      rowData={(data) => {
        const toExclude = [];
        if (roleCheck.isMerchant) {
          toExclude.push(["suspend", "approve"])
          if (data.status === Statuses.SUSPENDED) toExclude.push(["copy", "generate"])
        }

        if (roleCheck.isAdmins) {
          if (data.status === Statuses.SUSPENDED) toExclude.push(["suspend"])
          if (data.status === Statuses.APPROVED) toExclude.push(["approve"])
        }
        return {
          exclude: toExclude.flat(1),
          entries: [
            { value: formatDate(data.createdAt, "LL") },
            { value: data.name },
            { value: data.tillNumber },
            roleCheck.isAdmin ? { value: `${data.owner.firstName} ${data.owner.lastName}` }: null,
            { value: data.whiteListedIp },
            { value: maskString(data.auth.secret, 16, 4) },
            { value: `${data.revenueShare * 100}%`, align: "center"},
            { ...getStatus(data.status) },
          ].filter(row => row != null),
        };
      }}
      selectionOptions={appOperations.filter(
        (option) => roleCheck.isMerchant ? option.value === "generate" : true
      )}
      filterOptions={filterOptions}
      optionItems={appOperations}
    />
  );
};
export default AppList;
