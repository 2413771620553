import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  snack: {},
};
const snackBarSlice = createSlice({
  name: "snackbar",
  initialState,
  reducers: {
    showSnackBar: (state, action) => {
      state.snack = action.payload;
    }
  },
});
export const { showSnackBar } = snackBarSlice.actions;
export default snackBarSlice.reducer;