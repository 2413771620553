import Icon from "@mui/material/Icon";
import { styled } from "@mui/material/styles";
import { AppRoutes } from "./routes";
import { logOut } from "../utils/route-util";

const ListIconStyle = styled(Icon)({
  fontSize: "1.7rem !important",
});

const getIcon = (name) => <ListIconStyle children={name} />;

const navConfig = [
  {
    title: "Dashboard",
    path: AppRoutes.dashboard.summary,
    icon: getIcon("dashboard"),
  },
  {
    title: "Applications",
    path: AppRoutes.dashboard.apps,
    icon: getIcon("apps"),
  },
  {
    title: "Channels",
    path: AppRoutes.dashboard.channels,
    icon: getIcon("contactless"),
  },
  {
    title: "Charges",
    path: AppRoutes.dashboard.charges,
    icon: getIcon("paid"),
  },
  {
    title: "Users",
    path: AppRoutes.dashboard.users,
    icon: getIcon("groups"),
  },
  {
    title: "Transfers",
    path: AppRoutes.dashboard.transfers,
    icon: getIcon("move_up"),
  },
  {
    title: "Account",
    path: AppRoutes.dashboard.account,
    icon: getIcon("admin_panel_settings"),
  },

  {
    title: "Documentation",
    path: ``,
    icon: getIcon("integration_instructions"),
    action: () =>
      window.open("https://easypaytz.readme.io/reference/welcome", "_blank"),
  },
  {
    title: "Logout",
    path: ``,
    icon: getIcon("logout"),
    action: () => {
      logOut();
    },
  },
];

export default navConfig;
