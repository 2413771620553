import {
  CardMedia,
  Card,
  Grid,
  Typography,
  Link,
} from "@mui/material";
import { useState, useEffect } from "react";
import LoadingButton from "../component/button";
import Logo from "../component/logo";
import Status from "../component/status";
import { coreStyles } from "../../theme/style";
import { createSession, decodeBase64Token } from "../../utils/session-util";
import { getParams, preventIfNoValidToken } from "../../utils/route-util";
import { executePostRequest } from "../../utils/http-util";
import { Constants } from "../../utils/constants";
import { AppRoutes } from "../../router/routes";
import OTPInput from "otp-input-react";
import { setTitle } from "../../utils/util";

const label = "Verify MFA";

export const TwoFactorAuthConfig = () => {
  const { token } = getParams();
  const appStyle = coreStyles();
  const [otp, setOtp] = useState("");
  const [onAuthing, setOnAuthing] = useState(false);
  const [btnLabel, setLabel] = useState(label);
  const [qrCode, setQrCode] = useState(null);
  const [status, setStatus] = useState({ error: false, message: null });

  const handleSubmit = () => {
    executePostRequest(Constants.ENDPOINTS.MFA.VALIDATE,
      { token: otp },
      (loading) => {
        setLabel("");
        setOnAuthing(loading);
      }, { authorization: decodeBase64Token(token) }).then((result) => {
        setLabel(label);
        setStatus({
          ...{
            error: result.error,
            message: result.message,
          },
        });
        if (!result.error) createSession(result.data)
      });
  };

  useEffect(() => preventIfNoValidToken(false),// eslint-disable-next-line
    []);

  useEffect(() => {
    executePostRequest(Constants.ENDPOINTS.MFA.CONFIGURE, {}, (loading) => {
      setLabel("");
      setOnAuthing(loading);
    }, { authorization: decodeBase64Token(token) }).then(result => {
      setLabel(label);
      if (result.error) {
        setStatus({
          ...{
            error: true,
            message: result.message,
          },
        });
      } else {
        setQrCode(result.data.mfa.qr);
      }
    }
    );
  }, [token]);

  useEffect(() => {
    setTitle("Configure MultiFactor Authentication");
    // eslint-disable-next-line
  }, [otp, btnLabel, onAuthing]);

  return (
    <Grid
      container
      className={appStyle.container}
      justifyContent="center"
      elevation={4}
      alignItems="center"
    >
      <Grid item textAlign="center" xs={12}>
        <Logo image="easypay-logo-white.svg" />
      </Grid>
      <Grid item xs={11} md={5}>
        <Card className={appStyle.loginCard}>
          <Typography variant="h2" color="primary.darker" align="center" mb={2}>
            Scan from Auth App
          </Typography>

          {status && status.message && (
            <Grid item xs={12} mb={4}>
              <Status
                error={status.error}
                timeout={5000}
                message={status.message}
                onClose={() => {
                  if (status.error) {
                    setStatus({ ...{ message: null } });
                  }
                }}
              />
            </Grid>
          )}
          {qrCode && (
            <Grid container justifyContent="center">
              <Grid item mt={2} mb={3}>
                <CardMedia component="img" height="200px" image={qrCode} />
              </Grid>
            </Grid>
          )}

          <Grid item xs={12} mt={10} mb={5} sx={{ pl: 3 }}>

            <p align="center">
              <OTPInput
                value={otp}
                inputClassName={appStyle.otpInput}
                onChange={setOtp}
                autoFocus OTPLength={6}
                otpType="number" disabled={false} />
            </p>
      
          </Grid>

          <Grid item xs={12} mb={3}>
            <LoadingButton
              onClick={handleSubmit}
              loading={onAuthing}
              disabled={otp.length < 6 || onAuthing || (!status.error && status.message)}
              label={btnLabel}
            />
          </Grid>
          <p align="center">
            <Link
              href={AppRoutes.auth.login}
              variant="body2"
              className={appStyle.links}
            >
              &nbsp;&nbsp;Go back to Login
            </Link>
          </p>
        </Card>
      </Grid>
    </Grid>
  );
};
