import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  navigate: () => {}
};

const navSlice = createSlice({
  name: "nav",
  initialState,
  reducers: {
    updateNavigator: (state, action) => {
      state.navigate = action.payload;
    }
  },
});
export const { updateNavigator } = navSlice.actions;
export default navSlice.reducer;