import { Card, Link, Grid, Box, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { AppRoutes } from "../../router/routes";
import { coreStyles } from "../../theme/style";
import { Constants } from "../../utils/constants";
import { preventIfConfiguration } from "../../utils/route-util";
import { createSession } from "../../utils/session-util";
import { validateEmail, noEmptyFields, isError } from "../../utils/validation-util";
import LoadingButton from "../component/button";
import Logo from "../component/logo";
import Status from "../component/status";
import EasyTextEdit from '../component/text-edit';
import { executePostRequest } from "../../utils/http-util";
import { setTitle } from "../../utils/util";

const label = "Sign In";

const validationRef = {
  emailAddress: { error: true, touched: false },
  password: { error: true, touched: false },
};

const authPropsRef = {
  emailAddress: "",
  password: "",
};

export const AuthLogin = () => {
  const appStyle = coreStyles();
  const [onAuthing, setOnAuthing] = useState(false);
  const [btnLabel, setLabel] = useState(label);
  const [validation, setValidation] = useState(validationRef);
  const [validated, setValidated] = useState(false);
  const [authProps] = useState(authPropsRef);
  const [status, setStatus] = useState({ error: false, message: null });

  const handleSubmit = (event) => {
    event.preventDefault();
    setLabel("");
    executePostRequest(Constants.ENDPOINTS.AUTHS.LOGIN, authProps, (loading) =>
      setOnAuthing(loading)
    ).then((result) => {
      setLabel(label);
      if (result.error) {
        setStatus({
          ...{
            error: result.error,
            message: result.message,
          },
        });
      } else {
        createSession(result.data);
      }
    });
  };

  const onValueChanged = (value, isEmail) => {
    authProps[isEmail ? "emailAddress" : "password"] = value;
    if (isEmail) {
      validation.emailAddress = { error: !validateEmail(value), touched: true };
    }
    if (!isEmail) {
      validation.password = { error: value.length < 6, touched: true };
    }
    setValidation({ ...validation });
    setValidated(noEmptyFields(validation));
  };

  useEffect(() => preventIfConfiguration(), []);

  useEffect(() => {
    setTitle("Log in into your EasyPay account");
    setValidated(noEmptyFields(validation));
    // eslint-disable-next-line
  }, [authProps.emailAddress, authProps.password]);

  return (
    <Grid
      container
      className={appStyle.container}
      justifyContent="center"
      elevation={4}
      alignItems="center"
    >
      <Grid item textAlign="center" xs={12}>
        <Logo image="easypay-logo-white.svg" />
      </Grid>
      <Grid item xs={12} md={5}>
        <Card className={appStyle.loginCard}>
          <Typography
            variant="h2"
            color="primary.darker"
            align="center"
            mb={4}
            className={appStyle.title}
          >
            Welcome Back
          </Typography>
          <Box noValidate>
            {status && status.message && (
              <Grid item xs={12} mb={4}>
                <Status
                  error={status.error}
                  timeout={5000}
                  message={status.message}
                  onClose={() => {
                    if (status.error) {
                      setStatus({ ...{ message: null } });
                    }
                  }}
                />
              </Grid>
            )}

            <Grid container justifyContent="center" spacing={3}>
              <Grid item xs={12}>
                <EasyTextEdit
                  placeholder="Email address"
                  type="email"
                  id="emailAddress"
                  value={authProps.emailAddress}
                  errorText="Invalid email address"
                  error={isError(validation.emailAddress)}
                  disabled={onAuthing}
                  icon="alternate_email"
                  onChange={(value, field) => onValueChanged(value, true)}
                />
              </Grid>

              <Grid item xs={12}>
                <EasyTextEdit
                  placeholder="Password"
                  type="password"
                  id="password"
                  errorText={`Invalid password format`}
                  error={isError(validation.password)}
                  disabled={onAuthing}
                  icon="password"
                  value={authProps.password}
                  password={true}
                  onChange={(value, field) => onValueChanged(value, false)}
                />
              </Grid>
            </Grid>

            <Grid container justifyContent="end">
              <Grid item mt={2}>
                <Link
                  href={AppRoutes.auth.recover}
                  variant="body2"
                  color="text.primary"
                  className={appStyle.links}
                >
                  Forget Password?
                </Link>
              </Grid>
            </Grid>
            <LoadingButton
              onClick={handleSubmit}
              loading={onAuthing}
              disabled={!validated || (!status.error && status.message)}
              label={btnLabel}
            />

            <Grid container justifyContent="center">
              <Grid item mt={4} xs={12}>
                <p align="center">
                  <Typography
                    component="span"
                    variant="body2"
                    className={appStyle.link_span}
                  >
                    Don't have an account yet?
                  </Typography>
                  <Link
                    href={AppRoutes.account.create}
                    variant="body2"
                    className={appStyle.links}
                  >
                    &nbsp;&nbsp;Sign up
                  </Link>
                </p>
              </Grid>
            </Grid>
          </Box>
        </Card>
      </Grid>
    </Grid>
  );
};
