import { configureStore } from "@reduxjs/toolkit";
import navigatorReducer from "./navigator";
import snackbarReducer from "./snackbar";
import lavinMqReducer from "./lavinMq";
import enterReducer from "./enterpress";

export const AppStore = configureStore({
  reducer: {
    navigator: navigatorReducer,
    snackbar: snackbarReducer,
    lavinMq: lavinMqReducer,
    keyPress: enterReducer
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});
