import {
  Grid,
  Typography,
  FormControlLabel,
  InputAdornment,
  Checkbox,
} from "@mui/material";
import {
  Statuses,
  copyText,
  setTitle,
  showSnackMessage,
} from "../../utils/util";
import { useState, useEffect } from "react";
import { AppRoutes } from "../../router/routes";
import { getParams, navigate, preventIfNoSession } from "../../utils/route-util";
import EasyTextEdit from "../component/text-edit";
import EditPage from "../component/edit-page";
import { executeGetRequest, executePatchRequest, executePostRequest } from "../../utils/http-util";
import { cleanObject, isError, validateFields, validateIp, validateUrl } from "../../utils/validation-util";
import { userRoleCheck } from "../../utils/session-util";

const appRef = {
  name: "",
  callbackUrl: "https://api.easypay.co.tz/v2/webhook/default",
  callbackToken: null,
  tillNumber: null,
  whiteListedIp: null,
  isMobileSupported: true,
  isCardSupported: true,
  isBankSupported: true,
  revenueShare: null,
};

const validationRef = {
  name: { error: true, touched: false },
  callbackUrl: { error: false, touched: true },
  whiteListedIp: { error: false, touched: true },
  revenueShare: { error: false, touched: true }
};

const AppEdit = () => {
  preventIfNoSession();
  const maxCharacters = 30;
  const { uuid } = getParams();
  const roleCheck = userRoleCheck();
  const [app, setApp] = useState({ ...appRef });
  const [validation, setValidation] = useState(validationRef);
  const [validated, setValidated] = useState(false);
  const [status, setStatus] = useState({
    error: false,
    loading: false,
    message: null,
  });

  useEffect(() => {
    if(uuid){
      executeGetRequest(
        `apps?uuid=${uuid}`,
        (loading) => setStatus({ ...{ loading: loading } })
      ).then(_app => {
        if (_app) setApp({ ...app, ..._app , revenueShare: _app.revenueShare * 100});
      });
    }
    setTitle(uuid ? "Edit Application":"Create application")
    return () => setApp(appRef);
    // eslint-disable-next-line
  }, [uuid]);

  const createUpdate = () => {
    const revenueShare = app.revenueShare ? Math.abs(app.revenueShare) / 100: undefined;
    if (uuid) {
      executePatchRequest(
        `apps/${uuid}`,
        cleanObject({ ...app, revenueShare,  auth: undefined, owner: undefined }),
        (loading) => setStatus({ ...{ loading: loading } })
      ).then(result => setStatus({
        ...{
          error: result.error,
          message: !result.error ? "App updated successfully" : result.message,
        },
      }));
    } else {
      executePostRequest(
        `apps`,
        cleanObject({ ...app, revenueShare }),
        (loading) => setStatus({ ...{ loading: loading } })
      ).then(response => {
        setStatus({
          ...{
            error: response.error,
            message: !response.error ? "App created successfully" : response.message,
          },
        });
      });
    }

  };

  const updateAppName = (value) =>
    onValueChange(
      value.length > maxCharacters ? value.substring(0, maxCharacters) : value,
      "name"
    );

  const onValueChange = (value, tag) => {
    app[tag] = value;
    if (tag === "name") {
      validation.name = { error: value.length <= 4, touched: true };
    }

    if (tag === "callbackUrl") {
      validation.callbackUrl = {
        error: !validateUrl(value),
        touched: true,
      };
    }

    if (tag === "whiteListedIp") {
      validation.whiteListedIp = {
        error: !validateIp(value),
        touched: true,
      };
    }

    if (tag ==="revenueShare"){
      validation.revenueShare = {
        error: roleCheck.isAdmins ? !((+(value ? value:  0)) >= 0) : false,
        touched: true,
      };
    }
    setApp({ ...app });
    setValidation({ ...validation });
    setValidated(validateFields(validation));
  };

  return (
    <EditPage
      title={uuid ? `Edit ${app.name}` : "Create an app"}
      status={status}
      onSave={() => createUpdate()}
      validated={validated && !status.loading}
      onBack={() => navigate(`${AppRoutes.dashboard.apps}`)}
      onFinish={() => {
        setStatus({ ...{ loading: false, message: null, error: false } });
        if (!status.error) {
          setApp({ ...appRef });
          navigate(`${AppRoutes.dashboard.apps}`);
        }
      }}
    >
      <Grid
        item
        xs={12} md={6}
      >
        <EasyTextEdit
          placeholder="Application name"
          type="text"
          id="name"
          errorText="Invalid app name"
          error={isError(validation.name)}
          disabled={status.loading || (uuid && roleCheck.isMerchant && app.status !== Statuses.PENDING)}
          value={app.name}
          icon="apps"
          onChange={(value, _) => updateAppName(value)}
          endAdornment={
            <InputAdornment position="end">
              <Typography
                sx={{ mr: 4 }}
                variant="subtitle1"
                color="primary.light"
                edge="end"
              >
                {app.name.length} / {maxCharacters}
              </Typography>
            </InputAdornment>
          }
        />
      </Grid>

      <Grid item xs={12} md={roleCheck.isAdmins ? 3: 6}>
        <EasyTextEdit
          placeholder="Application ID"
          type="text"
          id="till"
          error={false}
          disabled={true}
          value={app.tillNumber || `Public ID not assigned`}
          icon="verified"
          onChange={(value, _) => { }}
        />
      </Grid>

      {roleCheck.isAdmins && (<Grid item xs={12} md={3}>
        <EasyTextEdit
          placeholder="Revenue Share"
          type="number"
          id="revenueShare"
          errorText="Invalid Percentage"
          error={isError(validation.revenueShare)}
          disabled={status.loading}
          value={app.revenueShare}
          icon="percentage"
          onChange={onValueChange}
        />
      </Grid>)}

      <Grid item xs={12} md={8}>
        <EasyTextEdit
          placeholder="Callback URL"
          type="text"
          id="callbackUrl"
          errorText="Invalid callback URL"
          error={isError(validation.callbackUrl)}
          disabled={status.loading}
          value={app.callbackUrl}
          icon="public"
          onChange={onValueChange}
        />
      </Grid>

      <Grid item xs={12} md={4}>
        <EasyTextEdit
          placeholder="IP Address to whitelist"
          type="text"
          id="whiteListedIp"
          errorText="Invalid IP address"
          error={isError(validation.whiteListedIp)}
          disabled={status.loading}
          value={app.whiteListedIp}
          icon="dns"
          onChange={onValueChange}
        />
      </Grid>

      {uuid && (<Grid item xs={12} mt={3} sx={{pb: -1}}>
        <Typography color="primary" variant={"subtitle1"}>Security</Typography>
      </Grid>)}

      {uuid && (<Grid item xs={12} md={12}>
        <Typography color="text.disabled" sx={{ mb: 1 }}>Client ID</Typography>
        <EasyTextEdit
          placeholder="Client ID"
          type="text"
          id="clientId"
          errorText=""
          error={false}
          disabled={true}
          value={app.auth && app.auth.client}
          icon="content_copy"
          endAdo={true}
          startAdo={false}
          onAdoClicked={() => {
            copyText(app.auth.client);
            showSnackMessage("Client ID was copied to a clipboard ");
          }}
          onChange={onValueChange}
        />
      </Grid>)}

      {uuid && (<Grid item xs={12} md={12}>
        <Typography color="text.disabled" sx={{ mb: 1 }}>Client Secret</Typography>
        <EasyTextEdit
          placeholder="Client Secret"
          type="text"
          id="secret"
          errorText=""
          error={false}
          disabled={true}
          value={app.auth && app.auth.secret}
          icon="content_copy"
          endAdo={true}
          startAdo={false}
          onAdoClicked={() => {
            copyText(app.auth.secret);
            showSnackMessage("Client secret was copied to a clipboard ");
          }}
          onChange={onValueChange}
        />
      </Grid>)}

      {uuid && (<Grid item xs={12} md={12}>
        <Typography color="text.disabled" sx={{ mb: 1 }}>Callback Token</Typography>
        <EasyTextEdit
          placeholder="Token"
          type="text"
          id="callbackToken"
          error={false}
          disabled={true}
          icon="content_copy"
          value={app.callbackToken && app.callbackToken}
          endAdo={true}
          startAdo={false}
          onAdoClicked={() => {
            copyText(app.callbackToken);
            showSnackMessage("Callback Token was copied to a clipboard ");
          }}
          onChange={onValueChange}
        />
      </Grid>)}

      <Grid item xs={12} mt={3}>
        <Typography color="primary" variant={"subtitle1"}>Supported Payments</Typography>
      </Grid>

      <Grid item xs={4}>
        <FormControlLabel
          disabled={false}
          value={app.isMobileSupported}
          control={<Checkbox defaultChecked />}
          label={
            <Typography variant="body2">
              Mobile payments <strong>(All MNOs)</strong>
            </Typography>
          }
        />
      </Grid>

      <Grid item xs={4}>
        <FormControlLabel
          disabled={false}
          value={app.isCardSupported}
          control={<Checkbox defaultChecked />}
          label={
            <Typography variant="body2">
              Card payments <strong>(Supported Cards)</strong>
            </Typography>
          }
        />
      </Grid>

      <Grid item xs={4}>
        <FormControlLabel
          disabled={false}
          value={app.isBankSupported}
          control={<Checkbox defaultChecked />}
          label={
            <Typography variant="body2">
              Bank payments <strong>(Supported Banks)</strong>
            </Typography>
          }
        />
      </Grid>

    </EditPage>
  );
};

export default AppEdit;
