export const Constants = {
  NAV_DELAY: 500,
  APP_NAME: "EasyPay",
  SESSION_TOKEN_TAG: "session_token",
  SESSION_TIMEOUT_KEY: "session_timeout",
  AUTH_TOKEN: "auth_token",
  NEXT_DESTINATION:"next-dest",
  STATUS: {
    PENDING: "PENDING",
    ACTIVE: "ACTIVE",
  },
  ENDPOINTS: {
    AUTHS: {
      LOGIN: "auths/authenticate",
      RECOVER_PASSWORD: "auths/recover-password",
      CHANGE_PASSWORD: "auths/change-password",
    },
    ACCOUNTS: {
      REGISTER: "users",
      ACTIVATE: "users/activate",
    },
    MFA: {
      CONFIGURE: "auths/configure-mfa",
      VALIDATE: "auths/validate-mfa"
    }
  },
};