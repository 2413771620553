import { createSlice } from "@reduxjs/toolkit";
export const initialState = {
  data: null,
};
const lavinMqSlice = createSlice({
  name: "lavinMq",
  initialState,
  reducers: {
    setLavinMqData: (state, action) => {
      state.data = action.payload;
    }
  },
});
export const { setLavinMqData } = lavinMqSlice.actions;
export default lavinMqSlice.reducer;