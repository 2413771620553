import {
  Autocomplete,
  Checkbox,
  Chip,
  Grid,
  TextField,
} from "@mui/material";
import { useState, useEffect } from "react";
import { getParams, navigate, preventIfNotAdmin } from "../../utils/route-util";
import EditPage from "../component/edit-page";
import { cleanObject, validateFields } from "../../utils/validation-util";
import { AppRoutes } from "../../router/routes";
import EasyTextEditField from "../component/text-field-edit";
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { executeGetRequest, executePatchRequest, executePostRequest } from "../../utils/http-util";
import { setTitle } from "../../utils/util";

const chanRef = {
  name: "",
  identifier: "",
  allowedCodes: []
};

const channels = [
  { id: "AIRTEL_MONEY", name: "Airtel Tanzania", codes: [68, 78, 69] },
  { id: "M_PESA", name: "M-Pesa Tanzania", codes: [74, 75, 76] },
  { id: "TIGO_PESA", name: "TigoPesa Tanzania", codes: [71, 65, 67, 77] },
  { id: "T_PESA", name: "T-Pesa Tanzania", codes: [73] },
  { id: "HALOPESA", name: "HaloPesa Tanzania", codes: [62] },
  { id: "CRDB", name: "CRDB PLC", codes: []},
  { id: "NMB", name: "National Microfinance Bank", codes: []}
]



const validationRef = {
  name: { error: true, touched: false },
  identifier: { error: true, touched: false },
};

const ChannelEdit = () => {
    preventIfNotAdmin();
    const { uuid } = getParams();
    const [channel, setChannel] = useState({...chanRef});
    const [validation, setValidation] = useState({...validationRef});
    const [validated, setValidated] = useState(false);
    const [allowedCodes, setAllowedCodes] = useState([]);
    const [status, setStatus] = useState({
        error: false,
        loading: false,
        message: null,
    });

  useEffect(() => {
    if (uuid) {
      executeGetRequest(
        `channels?uuid=${uuid}`,
        (loading) => setStatus({ ...{ loading: loading } })
      ).then(_channel => {
        if (_channel) {
          setChannel({ ...channel, ..._channel });
          setAllowedCodes(channels.filter(chan => chan.id === _channel.identifier)[0].codes)
          setValidated(true);
        };
      });
    }
    setTitle(uuid ? "Edit Payment Channel":"Create Payment Channel")
    // eslint-disable-next-line
  }, [uuid]);

  const createUpdate = () => {
    if (uuid) {
      executePatchRequest(
        `channels/${uuid}`,
        cleanObject({ ...channel }),
        (loading) => setStatus({ ...{ loading: loading } })
      ).then(result => setStatus({
        ...{
          error: result.error,
          message: !result.error ? "Payment channel updated successfully" : result.message,
        },
      }));
    } else {
      executePostRequest(
        `channels`,
        cleanObject(channel),
        (loading) => setStatus({ ...{ loading: loading } })
      ).then(response => {
        setStatus({
          ...{
            error: response.error,
            message: !response.error ? "Payment channel created successfully" : response.message,
          },
        });
      });
    }

  };

  const onValueChange = (value, tag) => {
    channel[tag] = value;
    if (tag === "name") {
      validation.name = { error: value.length <= 4, touched: true };
    }

    if (tag === "identifier") {
      validation.identifier = { error: value.length <= 4, touched: true };
    }


    setChannel({ ...channel }); 
    setValidation({ ...validation });
    setValidated(validateFields(validation));
  };

  return (
    <EditPage
      title={!uuid ? "Edit Channel" : "Create Channel"}
      status={status}
      onSave={() => createUpdate()}
      validated={validated && !status.loading && channel.allowedCodes.length}
      onBack={() => navigate(`${AppRoutes.dashboard.channels}`, true)}
      onFinish={() => {
        setStatus({ ...{ loading: false, message: null, error: false } });
        if (!status.error) {
          setChannel({ ...chanRef });
          navigate(`${AppRoutes.dashboard.channels}`, true);
        }
      }}
    >
      <Grid item xs={12} sm={6}>
        <EasyTextEditField
          placeholder="Channel Identifier"
          type="text"
          id="identifier"
          errorText="Invalid Identifier"
          disabled={status.loading}
          value={channel.identifier}
          items={channels.map(chan => {
            return {value: chan.id, label: chan.name}
          })}
          icon="contactless"
          onChange={(value, tag) => {
            const chan = channels.filter(chan => chan.id === value)[0];
            onValueChange(chan.name, "name");
            onValueChange(chan.id, tag);
            setAllowedCodes(chan.codes);
            setChannel({...channel, allowedCodes: []});
          }}
        />
      </Grid>

      <Grid item xs={12} sm={6}>
        <Autocomplete
          multiple={true}
          id="allowedCodes"
          disableCloseOnSelect
          limitTags={6}
          disabled={status.loading}
          value={channel.allowedCodes}
          renderTags={(value, getTagProps) =>
            value.map((option, index) => (
              <Chip variant="filled" label={option} {...getTagProps({ index })} />
            ))
          }
          renderOption={(props, option, { selected }) => (
            <li {...props}>
              <Checkbox
                icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                checkedIcon={<CheckBoxIcon fontSize="small" />}
                style={{ marginRight: 8 }}
                checked={selected}
              />
              {allowedCodes.filter(code => option === code)[0]}
            </li>
          )}
          options={allowedCodes.map((code) => code)}
          renderInput={(params) => (
            <TextField {...params} label="Allowed MSSID Prefix" />
          )}
          onChange={(event, value) => {
            setChannel({ ...channel, allowedCodes: value })
          }}
        />
      </Grid>
    </EditPage>
  );
};

export default ChannelEdit;