import { useState, useEffect } from "react";
import { preventIfNotAdmin, navigate } from "../../utils/route-util";
import { getState,  setTitle,  Statuses } from "../../utils/util";
import DataList from '../component/@list/list';
import { AppRoutes } from "../../router/routes";
import { executeDeleteRequest, executeGetRequest } from "../../utils/http-util";

const TABLE_HEAD = [
  { id: "name", label: "Name", alignRight: false },
  { id: "identifier", label: "Identifer", alignRight: false },
  { id: "allowedCodes", label: "Codes", alignRight: false },
  { id: "_" },
];



const ChannelList = () => {

  preventIfNotAdmin()
  const [dataList, setDataList] = useState([]);
  const [loading, onLoading] = useState(false);
  const [filterKey, setFilterkey] = useState("createdAt");

  const operationOptions = [
    {
      icon: "delete",
      value: "delete",
      label: "Delete",
      onClick: (value, selected) =>{
        onLoading(true);
        Promise.all(selected.map(uuid => executeDeleteRequest(`channels/${uuid}`)))
          .then(result => executeFetch())
      }
    },
    {
      icon: "edit",
      value: "edit",
      label: "Edit",
      onClick: (_value, selected) =>
        navigate(
          `${AppRoutes.dashboard.channelEdit}?uuid=${selected.join("")}`,
          true
        ),
    }
  ];

  const filterOptions = [
    { value: "name", label: "Channel name", onClick: setFilterkey },
    { value: "identifier", label: "Identifier", onClick: setFilterkey }
  ];



  const executeFetch = () => {
    executeGetRequest(
      "channels",
      (loading) => onLoading(loading)
    ).then(result => {
      if (result) setDataList(result);
    });
  };

  useEffect(() => {
    executeFetch();
    setTitle("Payment Channels")
  }, []);

  return (
    <DataList
      sectionTitle="Channels"
      createNewLabel={"Create Channel"}
      data={dataList}
      primaryKey="uuid"
      filterColumn={filterKey}
      emptyIcon="contactless"
      orderIn="asc"
      loading={loading}
      onCreateNewItem={() =>
        navigate(AppRoutes.dashboard.channelEdit)
      }
      headers={TABLE_HEAD}
      rowData={(data) => {
        return {
          exclude: [],
          entries: [
            { value: data.name },
            { value: data.identifier},
            { value: data.allowedCodes.join(", ") },
            { ...getState(data.active ? Statuses.APPROVED: Statuses.FAILED) }
          ],
        };
      }}
      selectionOptions={operationOptions.filter(op => op.value === "delete")}
      filterOptions={filterOptions}
      optionItems={operationOptions}
    />
  );
};
export default ChannelList;