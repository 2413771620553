import SessionManager from "clientside-session-manager";
import { getActiveUser, nextDestination, UserRole } from "./session-util";
import { Constants } from "./constants";
import { AppStore } from "./@redux/store";
import { AppRoutes } from "../router/routes";

export const navigate = (destination) => {
  AppStore.getState().navigator.navigate(destination);
};

export const logOut = (navigate = true) => {
  SessionManager.destroy();
  if(navigate) {
    window.open(`${window.location.origin}${AppRoutes.auth.login}`, "_self")
  }
};

export const getRoute = (destination, index = 2) => {
  return destination.split("/")[index];
};

export const getParams = () => {
  if (window.location.search) {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const objs = Object.fromEntries(urlSearchParams.entries());
    var _objs = {};
    for (const key in objs) {
      _objs[`${key}`] =
        objs[key] === "true" || objs[key] === "false"
          ? JSON.parse(objs[key])
          : objs[key];
    }
    return _objs;
  } else {
    return {};
  }
};

export const preventIfNoValidToken = (withVerifier = true) => {
  const { token, verifier } = getParams();
  if (!token || (!verifier && withVerifier)) setTimeout(() => logOut(), Constants.NAV_DELAY);
};


export const preventIfNoValidLinkToken = () => {
  const { token } = getParams();
  if (!token || `${token}`.length <= 64) setTimeout(() => logOut(), Constants.NAV_DELAY);
};

export const preventIfNotAdmin = () => {
  preventIfNoSession();
  const user = getActiveUser();
  if (![UserRole.ADMIN_SUPER, UserRole.ADMIN].includes(user.role)) nextDestination();
}

export const preventIfSession = () => {
  const user = getActiveUser();
  if (user) setTimeout(() => logOut(), Constants.NAV_DELAY);
};

export const preventIfNoSession = () => {
  const user = getActiveUser();
  const isVerify = window.location.href.includes(AppRoutes.twoFa.verify);
  const isConfig = window.location.href.includes(AppRoutes.twoFa.configure);
  if (
    !user ||
    (user && ((isVerify && !user.verifyMfa) || (isConfig && !user.configMfa)))
  ) {
    setTimeout(() => logOut(), Constants.NAV_DELAY);
  }
};

export const preventIfConfiguration = () => nextDestination();
