import PropTypes from "prop-types";
import { Link as RouterLink } from "react-router-dom";
import { Box } from "@mui/material";
import { filesUrl } from "../../utils/http-util";

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  image: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = true, height = 150, width= 300, image = "easypay-logo.svg", sx }) {
  const logo = (
    <Box
      component="img"
      src={`${filesUrl}${image}`}
      sx={{ width: width, height: height, mb: 2, ...sx }}
    />
  );

  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="/">{logo}</RouterLink>;
}
