import {
  Typography,
  Grid,
  Card,
  Box,
  Icon,
  CircularProgress,
} from "@mui/material";
import { useState, useEffect } from "react";
import Logo from "../component/logo";
import { Constants } from "../../utils/constants";
import { coreStyles } from "../../theme/style";
import { executePostRequest } from "../../utils/http-util";
import { getParams, preventIfNoValidToken } from "../../utils/route-util";
import { decodeBase64Token, goBackToLogin } from "../../utils/session-util";
import { setTitle } from "../../utils/util";

export const ActivateAccount = () => {
  const appStyle = coreStyles();
  const { token, verifier } = getParams()
  const [status, setStatus] = useState({ error: false, message: null });


  useEffect(() => preventIfNoValidToken(), []);

  useEffect(() => {
    executePostRequest(
      Constants.ENDPOINTS.ACCOUNTS.ACTIVATE,
      { verifier },
      (loading) => setStatus({
        ...{ loading },
      }),
      { authorization: decodeBase64Token(token) }
    ).then(result => {
      setStatus({
        ...{
          error: result.error,
          message: result.error ? "Expired or Invalid Token":result.message,
        },
      });
      goBackToLogin();
    });
    setTitle("Verifying Account")
    // eslint-disable-next-line
  }, [token]);

  return (
    <Grid
      container
      className={appStyle.container}
      justifyContent="center"
      elevation={4}
      alignItems="center"
    >
      <Grid item textAlign="center" xs={12}>
        <Logo image="easypay-logo-white.svg" />
      </Grid>
      <Grid item xs={11} md={5}>
        <Card className={appStyle.loginCard}>
          <Grid container justifyContent="center">
            <Grid
              item
              mt={6}
              mb={5}
              xs={12}
              container justifyContent="center"
              className={ status.message ? appStyle.show : appStyle.hide }
            >
              <Icon
                children="check_circle_outline"
                color={status.error ? "error" : "success"}
                className={appStyle.icon_standalone}
              />

            </Grid>

            <Grid item
              mt={6}
              mb={8}
              xs={12}
              className={ status.message ? appStyle.show : appStyle.hide
              }
            >
              <Typography
                align="center"
                variant="h4"
                className={appStyle.textPadding}
              >
                {status.message}
                <br />
              </Typography>
            </Grid>

          </Grid>
          <Box
            noValidate
            sx={{ mt: 1, mb: 6, py: 5 }}
            className={ status.message ? appStyle.hide : appStyle.show
            }
          >

            <Grid container justifyContent="center">
              <Grid
                item
                mb={3}
                className={ status.message ? appStyle.hide : appStyle.show
                }
              >
                <CircularProgress
                  color="primary"
                  size="7rem"
                />

              </Grid>

              <Grid item
                mt={6}
                xs={12}
                className={ status.message ? appStyle.hide : appStyle.show
                }>
                <Typography
                  align="center"
                  variant="h4"
                  className={appStyle.textPadding}
                >
                  Activating your account...
                  <br />
                </Typography>
              </Grid>
            </Grid>
          </Box>
        </Card>
      </Grid>
    </Grid>
  );
}
