import moment from "moment";

export const formatDate = (timestamp, format = "LLL") => {
  return moment(timestamp).format(format);
};

export const aboutToExpire = (timestamp, minutes = 2) => {
  const diff = moment(timestamp * 1000).diff(moment(), "minutes");
  return diff <= minutes;
}

export const expired = (timestamp) => {
  const diff = moment(timestamp * 1000).diff(moment(), "minutes");
  return diff < 0;
}

export const lastSeenOn = (date) => {
  const momentDate = moment(date);
  const now = moment();

  const minutes = now.diff(momentDate, 'minutes');
  const hours = now.diff(momentDate, 'hours');
  const days = now.diff(momentDate, 'days');
  const months = now.diff(momentDate, 'months');

  if (months > 0) {
    return `${months} month${months > 1 ? 's' : ''} ago`;
  } else if (days > 0) {
    return `${days} day${days > 1 ? 's' : ''} ago`;
  } else if (hours > 0) {
    return `${hours} hour${hours > 1 ? 's' : ''} ago`;
  } else if (minutes > 0) {
    return `${minutes} minute${minutes > 1 ? 's' : ''} ago`;
  } else {
    return 'Just now';
  }
}

export const millisToMinutesAndSeconds = (millis) => {
  var minutes = Math.floor(millis / 60000);
  var seconds = ((millis % 60000) / 1000).toFixed(0);
  return `Waiting for ${minutes + "m:" + (seconds < 10 ? '0' : '') + seconds}s`;
}