import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import ThemeProvider from "./theme/index";
import { Provider } from "react-redux";
import "./style.css";
import { disableReactDevTools } from "@fvilers/disable-react-devtools";
import { AppStore } from "./utils/@redux/store";
import AppRouter from "./router/app-router";
import { keyPress } from "./utils/@redux/enterpress";

if (process.env.NODE_ENV === "production") {
  disableReactDevTools();
}
const root = ReactDOM.createRoot(document.getElementById("root"));
document.body.style.zoom = "90%";
window.addEventListener("keydown",  (event) => {
  AppStore.dispatch(keyPress(event.key === 'Enter'));
});
root.render(
  <Provider store={AppStore}>
    <ThemeProvider>
      <BrowserRouter>
        <AppRouter />
      </BrowserRouter>
    </ThemeProvider>
  </Provider>
);
