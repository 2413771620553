import ReCAPTCHA from "react-google-recaptcha";
import {
  Typography,
  Link,
  Grid,
  Card,
  Box,
  Icon,
} from "@mui/material";
import { useState, useEffect } from "react";
import LoadingButton from "../component/button";
import Logo from "../component/logo";
import Status from "../component/status";
import EasyTextEdit from "../component/text-edit";
import { Constants } from "../../utils/constants";
import { coreStyles } from "../../theme/style";
import {
  validateEmail,
  noEmptyFields,
  isError,
} from "../../utils/validation-util";
import { AppRoutes } from "../../router/routes";
import { executePostRequest } from "../../utils/http-util";
import { preventIfSession } from "../../utils/route-util";
import { goBackToLogin } from "../../utils/session-util";
import { setTitle } from "../../utils/util";

const label = "Retrive Password";
const authRef = { emailAddress: ""};
const validationRef = {
  emailAddress: { error: true, touched: false },
  capture: { error: true, touched: false },
};

export const AuthRecoverPassword = () => {
  const appStyle = coreStyles();
  const [onProcessing, setOnProcessing] = useState(false);
  const [authProps, setAuthProps] = useState(authRef);
  const [btnLabel, setLabel] = useState(label);
  const [validated, setValidated] = useState(false);
  const [validation, setValidation] = useState(validationRef);
  const [status, setStatus] = useState({error: false,message: null});

  const handleSubmit = (event) => {
    event.preventDefault();
    setLabel("");
    executePostRequest(
      Constants.ENDPOINTS.AUTHS.RECOVER_PASSWORD,
      authProps,
      (loading) => {
        setLabel("");
        setOnProcessing(loading);
      }
    ).then(result => {
      setLabel(label);
      setStatus({
        ...{
          error: result.error,
          message: result.message,
        },
      });
      goBackToLogin();
    });
  };

  const onValueChanged = (value) => {
    authProps["emailAddress"] = value;
    validation.emailAddress = { error: !validateEmail(value), touched: true };
    update();
  };

  const onCaptchaChange = (value) => {
    validation.capture = { error: value.length < 20, touched: true };
    update();
  };

  const update = () => {
    setAuthProps({ ...authProps });
    setValidation({ ...validation });
    setValidated(noEmptyFields(validation));
  }

  useEffect(() => preventIfSession(), []);

  useEffect(() => {
    // eslint-disable-next-line
    setTitle("Forgot Password");
  }, [validation, btnLabel, onProcessing]);

  return (
    <Grid
      container
      className={appStyle.container}
      justifyContent="center"
      elevation={4}
      alignItems="center"
    >
      <Grid item textAlign="center" xs={12}>
        <Logo image="easypay-logo-white.svg" />
      </Grid>
      <Grid item xs={11} md={5}>
        <Card className={appStyle.loginCard}>
          <Typography
            variant="h2"
            align="center"
            color="primary.darker"
            mb={4}
            className={
              !status.error && status.message ? appStyle.hide : appStyle.title
            }
          >
            Forgot Password
          </Typography>
          <Grid container justifyContent="center">
            <Grid
              item
              mt={6}
              mb={5}
              className={
                !status.error && status.message ? appStyle.show : appStyle.hide
              }
            >
              <Icon
                children="check_circle_outline"
                color="success"
                className={appStyle.icon_standalone}
              />
            </Grid>
            <Grid
              item
              mb={4}
              xs={12}
              className={
                !status.error && status.message ? appStyle.hide : appStyle.show
              }
            >
              <Typography
                align="center"
                variant="body1"
                className={
                  !status.error && status.message
                    ? appStyle.hide
                    : appStyle.sub_title
                }
              >
                Enter your email address to retrieve your password
              </Typography>
            </Grid>
            {status && status.message && status.error && (
              <Grid item xs={12}>
                <Status
                  error={status.error}
                  timeout={5000}
                  message={status.message}
                  onClose={() => {
                    if (status.error) {
                      setStatus({ ...{ message: null } });
                    }
                  }}
                />
              </Grid>
            )}
            <Grid
              item
              xs={12}
              mb={8}
              className={
                !status.error && status.message ? appStyle.show : appStyle.hide
              }
            >
              <Typography
                align="center"
                variant="h4"
                className={appStyle.textPadding}
              >
                {status.message}
                <br />
              </Typography>
            </Grid>
          </Grid>
          <Box
            noValidate
            sx={{ mt: 1, mb: 6 }}
            className={
              !status.error && status.message ? appStyle.hide : appStyle.show
            }
          >
            <EasyTextEdit
              placeholder="Email address"
              type="email"
              id="emailAddress"
              errorText="Invalid email address"
              error={isError(validation.emailAddress)}
              disabled={onProcessing}
              value={authProps.emailAddress}
              icon="alternate_email"
              onChange={(value, _) => onValueChanged(value)}
              sx={{ mt: 1, mb: 4 }}
            />

            <Grid container justifyContent="center">
              <Grid item sx={{ mb: 2 }}>
                <ReCAPTCHA
                  sitekey="6Les68UfAAAAABv9dQCrFBCGjeo4A86aJ2uqyr3R"
                  size="normal"
                  onChange={onCaptchaChange}
                />
              </Grid>
            </Grid>

            <LoadingButton
              onClick={handleSubmit}
              loading={onProcessing}
              disabled={!validated}
              label={btnLabel}
            />
          </Box>
          <p align="center">
            <Link
              href={AppRoutes.auth.login}
              variant="body2"
              className={appStyle.links}
            >
              &nbsp;&nbsp;Go back to Login
            </Link>
          </p>
        </Card>
      </Grid>
    </Grid>
  );
}
