export const AppRoutes = {
  auth: {
    index: "/auth",
    login: "/auth/login",
    recover: "/auth/recover-pasword",
    reset: "/auth/reset-password",
  },
  twoFa: {
    index:"/2faAuth",
    configure:"/2faAuth/configure",
    verify:"/2faAuth/verify"
  },
  account: {
    create: "/account/create",
    activate: "/account/activate",
  },
  paymets: {
    link: "/payments/link",
    isntallment: "/payments/installment",
  },
  legal: {
    index: "/legal"
  },
  dashboard: {
    index: "/dashboard",
    summary: "/dashboard/",
    apps: "/dashboard/apps",
    appEdit: "/dashboard/apps/app",
    channels: "/dashboard/channels",
    channelEdit: "/dashboard/channels/channel",
    users: "/dashboard/users",
    userEdit: "/dashboard/users/user",
    charges: "/dashboard/charges",
    chargeEdit: "/dashboard/charges/charge",
    mailing: "/dashboard/mailing",
    account: "/dashboard/account",
    reports: "/dashboard/reports",
    transfers: "/dashboard/transfers",
  },
};
