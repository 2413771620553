import {
  Statuses,
  setTitle,
  getStatus,
} from "../../utils/util";
import { useState, useEffect } from "react";
import { executeGetRequest, executePatchRequest } from "../../utils/http-util";
import { UserRole, userRoleCheck } from "../../utils/session-util";
import { navigate, preventIfNotAdmin } from "../../utils/route-util";
import { formatDate, lastSeenOn } from "../../utils/date-util";
import { AppRoutes } from "../../router/routes";
import DataList from "../component/@list/list";

const TABLE_HEAD = [
  { id: "createdAt", label: "Created On", alignRight: false },
  { id: "name", label: "Full Name", alignRight: false },
  { id: "emailAddress", label: "Email Address", alignRight: false },
  { id: "role", label: "Role", alignRight: false },
  { id: "lastSeen", label: "Last Seen", alignRight: false },
  { id: "active", label: "Status", alignRight: false },
  { id: "_" },
];

export const UserList = () => {
  const roleCheck = userRoleCheck();
  const [dataList, setDataList] = useState([]);
  const [loading, onLoading] = useState(false);
  const [filterKey, setFilterkey] = useState("createdAt");

  const appOperations = [
    {
      icon: "edit",
      value: "edit",
      label: "Edit",
      onClick: (_value, selected) => navigate(`${AppRoutes.dashboard.userEdit}?uuid=${selected.join("")}`),
    },
    {
      icon: "lock_clock",
      value: "suspend",
      label: "Suspend",
      onClick: (_, selected) => {
        onLoading(true);
        Promise.all(selected.map(uuid => executePatchRequest(`users/${uuid}`, { status: Statuses.SUSPENDED })))
          .then(result => executeFetch())
      }
    }, {
      icon: "preview",
      value: "view",
      label: "View Info",
      onClick: (_, selected) => {
        onLoading(true);
        Promise.all(selected.map(uuid => executePatchRequest(`users/${uuid}`, { status: Statuses.SUSPENDED })))
          .then(result => executeFetch())
      }
    },
    {
      icon: "done_all",
      value: "approve",
      label: "Activate",
      onClick: (_, selected) => {
        onLoading(true);
        Promise.all(selected.map(uuid => executePatchRequest(`users/${uuid}`, { status: Statuses.ACTIVE })))
          .then(result => executeFetch())
      }
    },
  ];

  const filterOptions = [
    { value: "name", label: "User's name", onClick: setFilterkey },
    { value: "role", label: "Role", onClick: setFilterkey },
    { value: "createdAt", label: "Created On", onClick: setFilterkey },
  ];

  const executeFetch = () => {
    executeGetRequest(`users?role=${UserRole.MERCHANT}`,
      (loading) => onLoading(loading)
    ).then(result => setDataList(result.filter(app => app.auth)));
  };

  useEffect(() => {
    preventIfNotAdmin();
    executeFetch(true);
    setTitle("System Users")
    // eslint-disable-next-line
  }, []);

  return (
    <DataList
      sectionTitle="Users"
      createNewLabel={"Add User"}
      data={dataList}
      primaryKey="uuid"
      filterColumn={filterKey}
      emptyIcon="group"
      avatarKey="name"
      loading={loading}
      onCreateNewItem={() =>
        navigate(`${AppRoutes.dashboard.userEdit}`, true)
      }
      headers={TABLE_HEAD}
      rowData={(data) => {
        const toExclude = [];
        if (data.status === Statuses.SUSPENDED) toExclude.push(["suspend"])
        if (data.status === Statuses.ACTIVE) toExclude.push(["approve"])
        if (data.role === UserRole.MERCHANT) toExclude.push(["edit"])
        return {
          exclude: toExclude.flat(1),
          entries: [
            { value: formatDate(data.createdAt, "LL") },
            { value: `${data.firstName} ${data.lastName}` },
            { value: data.emailAddress },
            { value: data.role },
            { value: lastSeenOn(data.lastSeen) },
            { ...getStatus(data.status) },
          ].filter(row => row != null),
        };
      }}
      selectionOptions={appOperations.filter(
        (option) => roleCheck.isMerchant ? option.value === "generate" : true
      )}
      filterOptions={filterOptions}
      optionItems={appOperations}
    />
  );
};
