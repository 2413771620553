import { showSnackBar } from "./@redux/snackbar";
import { AppStore } from "./@redux/store";
import copy from "copy-to-clipboard";
import numeral from "numeral";
const { v4: uuidv4 } = require('uuid');


export const generateUuid = () => uuidv4();

export const showSnackMessage = (message) => {
  AppStore.dispatch(showSnackBar({ message: message }));
};

export const copyText = (text) => {
  copy(text);
};

export const setTitle = (title) => {
  document.title = `${title} - EasyPay.co.tz`;
}

export const maskString = (text, part = 8, end = part / 2) => {
  return text.length < part
    ? text
    : `${text.substring(0, part)} ... ${text.substring(text.length - end)}`;
};

export const getAvatarLabel = (objects, mKey) => {
  var keys = Object.keys(objects),
    label = null;
  for (let step = 0; step < keys.length; step++) {
    if (keys[step].includes(mKey) && objects[keys[step]]) {
      label = objects[keys[step]];
    }
  }
  if (label) {
    return `${label.split(" ")[0]}`.substring(0, 1).toUpperCase();
  }
  return ".";
};


export const Statuses = {
  PENDING: "PENDING",
  LOGGED: "LOGGED",
  COMPPLETED: "COMPLETED",
  FAILED: "FAILED",
  BUYING: "PAYING",
  NOTFOUND: "NOT FOUND",
  APPROVED: "APPROVED",
  ACTIVE: "ACTIVE",
  SUSPENDED: "SUSPENDED"
};


export const getStatus = (status) => {
  return {
    isLabel: true,
    value: status === Statuses.PENDING ? Statuses.PENDING 
    : status === Statuses.APPROVED ? Statuses.APPROVED 
        : status === Statuses.ACTIVE ? Statuses.ACTIVE 
    : Statuses.SUSPENDED,
    color: status === Statuses.PENDING ? "info" : (status === Statuses.APPROVED || status === Statuses.ACTIVE)? "success" : "error",
  };
};

export const getTransactionStatus = (status) => {
  return {
    isLabel: true,
    value: status === Statuses.PENDING ? Statuses.PENDING : status === Statuses.COMPPLETED 
    ? Statuses.COMPPLETED : status === Statuses.LOGGED ? Statuses.LOGGED: Statuses.FAILED,
    color: (status === Statuses.PENDING || status === Statuses.LOGGED) ? "info" :
     status === Statuses.COMPPLETED ? "success" : "error",
  };
};

export const getState = (active) => {
  return {
    isLabel: true,
    value: active ? "ACTIVE":"INNACTIVE",
    color: active ? "success" : "error",
  };
};

export const formatCurrency = (number, currency = "Tsh") => {
  return `${currency} ${numeral(number).format("0,0")}`;
};

export const formatCurrencyShort = (number, currency = "Tsh") => {
  return `${currency} ${numeral(number).format("0,0 a")}`;
};


