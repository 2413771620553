import { phone } from "phone";
import { passwordStrength } from "check-password-strength";

export const noEmptyFields = (fields) => {
  var validated = [];
  Object.keys(fields).forEach((key) => {
    validated.push(!fields[key].error && fields[key].touched);
  });
  return validated.filter((entry) => !entry).length === 0;
};

export const validateUrl = (value) => {
  return /^(?:(?:(?:https?):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:[/?#]\S*)?$/i.test(
    value
  );
};

export const validateIp = (value) => {
  return /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/i.test(
    value
  );
};

export const validatePassword = (value) => {
  const level = passwordStrength(value).value;
  return {
    level:
      level === "Too weak"
        ? 25
        : level === "Weak"
        ? 50
        : level === "Medium"
        ? 75
        : 100,
    label: level,
  };
};

export const validateEmail = (value) => {
  return String(value)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};

export const validatePhone = (value) => {
  const phoneNumber = formatPhoneNumber(value, true);
  const validate = phone(phoneNumber, { country: "TZ" });
  return validate && validate.isValid && phoneNumber.length >= 12;
};

export const formatPhoneNumber = (
  phoneNumber,
  withCode = true,
  countryCode = "255"
) => {
  var formatted = "",
    phone = phoneNumber.trim();
  if (phone.startsWith(countryCode)) formatted = phone;
  else if (phone.startsWith(`+${countryCode}`))
    formatted = phone.substring(1, phone.length);
  else if (phone.startsWith("0"))
    formatted = `${countryCode}${phone.substring(1, phone.length)}`;
  else formatted = `${countryCode}${phone}`;
  return withCode ? formatted : formatted.substring(3, formatted.length);
};

export const validateName = (name) => {
  // eslint-disable-next-line
  const namePattern = /^[A-Za-zÀ-ÿ]+([ '-][A-Za-zÀ-ÿ]+)*$/;
  if (namePattern.test(name) && name.length >= 2 && name.length <= 50) {
    return true; // Valid name
  }
  return false;
};

export const isError = (field) => {
  return field.error && field.touched;
};

export const validateFields = (fields) => {
  var validated = [];
  Object.keys(fields).forEach((key) => {
    validated.push(!fields[key].error && fields[key].touched);
  });
  return validated.filter((entry) => !entry).length === 0;
};

export const cleanObject = (data) => {
  Object.keys(data).forEach((key) => {
    if (typeof data[key] === "string" && data[key].trim().length <= 0) {
      delete data[key];
    }
  });
  return data;
};
