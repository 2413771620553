export const serviceDef = "Welcome to EasyPay, the next generation payment gateway!";

export const effectiveDate = "01 May 2022";

export const updateDate = "01 May 2022";

export const LegalConstants = {
  titles: [
    "Terms & Conditions",
    "Privacy Policies"
  ],
  contents: [
    `## Introduction

${serviceDef}, the next generation payment gateway! By using our payment gateway services, you agree to comply with and be bound by the following terms and conditions. Please read them carefully.

## 1. Acceptance of Terms

By accessing or using EasyPay, you confirm that you accept these terms and conditions and that you agree to comply with them. If you do not agree to these terms, you must not use our services.

## 2. Services Provided

EasyPay provides a platform for processing payments between merchants and customers. Our services include:

- Payment processing
- Fraud detection and prevention
- Transaction reporting
- Customer support

## 3. User Responsibilities

As a user of EasyPay, you agree to:

### 3.1 Provide Accurate Information

- **Registration:** You must provide accurate, current, and complete information during the registration process, including your name, business name, contact information, and banking details.
- **Updates:** You are responsible for updating your information promptly if any changes occur. Failure to provide accurate information may result in service interruptions or account suspension.

### 3.2 Maintain Account Security

- **Credentials:** You are responsible for maintaining the confidentiality of your account credentials, including your username and password. Do not share your credentials with anyone.
- **Unauthorized Access:** You must notify EasyPay immediately if you suspect any unauthorized access to your account or any other security breach.

### 3.3 Compliance with Laws

- **Legal Compliance:** You agree to comply with all applicable laws, regulations, and industry standards related to your business and the use of EasyPay's services.
- **Prohibited Activities:** You must not use EasyPay for any illegal or unauthorized purpose, including but not limited to money laundering, fraud, or the sale of illegal goods or services.

### 3.4 Transaction Management

- **Monitoring Transactions:** You are responsible for monitoring your transactions for accuracy and reporting any discrepancies or unauthorized transactions to EasyPay promptly.
- **Refunds and Chargebacks:** You must handle customer refunds and chargebacks in accordance with applicable laws and EasyPay’s policies. Failure to do so may result in penalties or account suspension.

### 3.5 Communication and Support

- **Customer Support:** You are responsible for providing customer support to your clients regarding transactions processed through EasyPay. EasyPay will assist with technical issues, but you must handle customer inquiries related to your products or services.
- **Feedback:** You agree to provide feedback and suggestions to EasyPay to help improve our services.

### 3.6 Ethical Conduct

- **Fair Practices:** You must engage in fair and ethical business practices and not engage in any deceptive or misleading conduct.
- **Respect for Others:** You agree to treat all users, customers, and EasyPay personnel with respect and refrain from any abusive or harassing behavior.

### 3.7 Liability for Actions

- **Responsibility for Actions:** You are solely responsible for all activities conducted through your account, including any actions taken by employees, agents, or third parties who access your account.
- **Indemnification:** You agree to indemnify EasyPay against any claims, losses, liabilities, damages, costs, or expenses arising from your violation of these responsibilities or applicable laws.

## 4. Fees and Charges

### 4.1 Overview

EasyPay strives to provide transparent and fair pricing for our services. We do not charge installation or maintenance fees. Instead, our fees are based on a revenue share agreement with our users.

### 4.2 Transaction Fees

- **Revenue Share Agreement:** The transaction fee is determined based on a mutually agreed revenue share model between EasyPay and the merchant. This agreement outlines the percentage of each transaction that will be retained by EasyPay.
- **Fee Structure:** The specific percentage charged will depend on the volume of transactions processed and the nature of the services provided. Details of the fee structure will be provided in the revenue share agreement signed by both parties.
- **No Hidden Fees:** EasyPay is committed to transparency. There are no hidden fees associated with our services. All fees will be communicated clearly before the commencement of services.

### 4.3 Payment Terms

- **Deposit Schedule:** Deposits will be made weekly upon reaching a specified transaction threshold. This threshold will be outlined in the revenue share agreement. Once the threshold is met, the funds will be deposited into the merchant's specified account.
- **Payment Methods:** Merchants must specify where they want to receive their cut. This can include bank accounts, digital wallets, or other payment methods as agreed upon in the revenue share agreement. It is the responsibility of the merchant to ensure that the specified method is valid and operational.

### 4.4 Changes to Fees

- **Notification:** EasyPay reserves the right to adjust transaction fees as necessary. Any changes will be communicated to the merchants in advance, allowing for adequate notice.

## 5. Limitation of Liability

### 5.1 General Limitation

To the fullest extent permitted by law, EasyPay shall not be liable for any indirect, incidental, special, consequential, or punitive damages arising from or related to your use of our services. This includes, but is not limited to, damages for loss of profits, goodwill, use, data, or other intangible losses, even if we have been advised of the possibility of such damages.

### 5.2 Specific Exclusions

EasyPay’s liability is limited in the following ways:

- **Service Interruptions:** We are not liable for any temporary interruptions or failures in our services, including but not limited to system outages, maintenance, or technical issues beyond our control.
- **Data Loss:** EasyPay is only responsible for the transaction records stored on our servers. We are not liable for any loss of data or information outside of these transaction records that may occur as a result of using our services. Users are encouraged to maintain their own backups of important data.
- **Third-Party Actions:** EasyPay is not liable for any actions taken by third parties, including unauthorized access to your account or transactions that may occur due to your negligence.

### 5.3 Maximum Liability

In the event that EasyPay is found liable for any claim arising from your use of our services, our total liability shall not exceed the total fees paid by you to EasyPay during the six (6) months preceding the event giving rise to the liability.

### 5.4 Indemnification

You agree to indemnify and hold EasyPay harmless against any claims, losses, liabilities, damages, costs, or expenses (including reasonable attorneys' fees) arising out of your use of our services or violation of these terms.

## 6. Termination

We reserve the right to suspend or terminate your access to EasyPay at our discretion, without notice, for conduct that we believe violates these terms or is harmful to other users or our services.

## 7. Changes to Terms

EasyPay reserves the right to modify these terms and conditions at any time. We will notify you of any changes by posting the new terms on our website. Your continued use of our services after such changes constitutes your acceptance of the new terms.

## 8. Governing Law

These terms shall be governed by and construed in accordance with the laws of Tanzania. Any disputes arising in connection with these terms shall be subject to the exclusive jurisdiction of the courts of Tanzania.

## 9. Contact Information

If you have any questions about these terms and conditions, please contact us at:

- **Email:** support@easypay.co.tz

---

**Last Updated:** ${updateDate}
`,
    `
## Introduction

  ${serviceDef} Your privacy is important to us. This Privacy Policy outlines how we collect, use, disclose, and safeguard your information when you use our payment gateway services in Tanzania. By using our services, you agree to the collection and use of information in accordance with this policy.

## Information We Collect

We may collect the following types of information:

- **Personal Information:** Name, email address, phone number, and billing address.
- **Payment Information:** Credit card details, bank account information, phone numbers, and transaction history.
- **Device Information:** IP address, browser type, operating system.
- **Usage Data:** Information about how you use our services, including access times, pages viewed, and links clicked.

## How We Use Your Information

We use the information we collect for various purposes, including:

- **Transaction Processing:** To process payments and manage your transactions securely and efficiently.
- **Account Management:** To create and manage your account, ensuring you have access to our services.
- **Customer Support:** To provide customer support and respond to your inquiries, concerns, or complaints.
- **Communication:** To communicate with you about your account, including transaction confirmations, updates, and promotional offers.
- **Personalization:** To personalize your experience on our platform, tailoring content and recommendations to your preferences.
- **Improvement of Services:** To analyze usage patterns and improve our services, features, and user experience based on feedback and analytics.
- **Marketing Purposes:** To send you marketing communications, newsletters, and promotional materials, provided you have opted in to receive such communications.
- **Compliance and Legal Obligations:** To comply with legal requirements, regulations, and industry standards, and to protect our rights and interests.
- **Fraud Prevention:** To monitor transactions for potential fraudulent activity and take necessary actions to prevent fraud.

## Disclosure of Your Information

We may share your information in the following circumstances:

- **With Service Providers:** We may share your information with third-party vendors who provide services on our behalf, such as payment processing and customer support.
- **For Legal Reasons:** We may disclose your information if required by law or in response to legal requests.

## Data Security

We take the security of your information seriously and implement a variety of measures to protect it. These measures include:

- **Encryption:** We use advanced encryption protocols to secure sensitive data during transmission and storage. This ensures that your personal and payment information is protected from unauthorized access.
- **Access Controls:** Access to your personal information is restricted to authorized personnel only. We implement strict access controls to ensure that only those who need access to your information for legitimate purposes can view it.
- **Regular Security Audits:** We conduct regular security audits and assessments to identify and mitigate potential vulnerabilities in our systems. This helps us maintain a robust security posture.
- **Data Breach Response Plan:** In the unlikely event of a data breach, we have a response plan in place to quickly address the situation, notify affected users, and take steps to prevent future incidents.
- **Compliance with Standards:** We comply with industry standards and regulations related to data security, including PCI DSS (Payment Card Industry Data Security Standard) for payment processing.
- **User Education:** We encourage our users to adopt strong security practices, such as using complex passwords and regularly updating them, to further protect their accounts.

While we strive to protect your information, please be aware that no method of transmission over the internet or electronic storage is completely secure. Therefore, we cannot guarantee its absolute security.

## Your Rights

You have certain rights regarding your personal information, including:

- The right to access your personal information.
- The right to request correction of inaccurate data.
- The right to request deletion of your personal information.
- The right to object to the processing of your data.

To exercise these rights, please contact us using the information provided below.

## Changes to This Privacy Policy

We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on our website. You are advised to review this Privacy Policy periodically for any changes.

## Contact Us

If you have any questions or concerns about this Privacy Policy, please contact us at:


- **Email:** support@easypay.co.tz

---

**Effective Date:** ${effectiveDate}
`
  ]
}