import PropTypes from "prop-types";
import { useState } from "react";
import { coreStyles } from "../../theme/style";
import {
  InputAdornment,
  Icon,
  FormControl,
  OutlinedInput,
  IconButton,
  FormHelperText,
} from "@mui/material";

EasyTextEdit.propTypes = {
  disabled: PropTypes.bool,
  error: PropTypes.bool,
  password: PropTypes.bool,
  type: PropTypes.string,
  id: PropTypes.string.isRequired,
  multiline: PropTypes.bool,
  errorText: PropTypes.string,
  placeholder: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  startAdo: PropTypes.bool,
  endAdo: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  onAdoClicked: PropTypes.func,
  sx: PropTypes.object,
};

export default function EasyTextEdit({
  disabled = false,
  error = false,
  type = "text",
  errorText = null,
  placeholder,
  onAdoClicked,
  multiline = false,
  id,
  password = false,
  startAdo = true,
  endAdo = false,
  onChange,
  icon,
  value = "",
  sx,
}) {
  const [showPassword, setShowPassword] = useState(false);
  const appStyle = coreStyles();
  const ado = icon  && (
    <InputAdornment
      onClick={() => password ? setShowPassword(!showPassword) : onAdoClicked()}
      position="end"
      sx={{ml: 1, mr: 1}}
    >
      <IconButton edge="end" sx={{mr: 1}}>
        <Icon
          children={icon}
          className={appStyle.input_icons}
        />
      </IconButton>
    </InputAdornment>
  );

  const paswordAdo = icon && (
    <InputAdornment
      onClick={() => setShowPassword(!showPassword)}
      position="end"
      sx={{ ml: 1, mr: 1 }}
    >
      <IconButton edge="end" sx={{ml: 1, mr: 1}}>
        <Icon
          children={showPassword ? "visibility" : "visibility_off"}
          className={appStyle.input_icons}
        />
      </IconButton>
    </InputAdornment>
  );
  return (
    <FormControl variant="filled" fullWidth sx={{ ...sx }}>
      <OutlinedInput
        placeholder={placeholder}
        type={password ? (showPassword ? "text" : "password") : type}
        error={error}
        disabled={disabled}
        inputlabelprops={{ shrink: true }}
        className={appStyle.inputs}
        value={value}
        multiline={multiline}
        rows={multiline ? 2: 1}
        onChange={(event) => onChange(event.currentTarget.value, id)}
        startAdornment={startAdo && ado}
        endAdornment={(endAdo || password) && (password ? paswordAdo : ado)}
      />
      {error && errorText && <FormHelperText error>{errorText}</FormHelperText>}
    </FormControl>
  );
}
