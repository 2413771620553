import PropTypes from "prop-types";
import { useState } from "react";
import { coreStyles } from "../../theme/style";
import {
  InputAdornment,
  Icon,
  IconButton,
  TextField,
  MenuItem,
} from "@mui/material";

EasyTextEditField.propTypes = {
  disabled: PropTypes.bool,
  error: PropTypes.bool,
  password: PropTypes.bool,
  type: PropTypes.string,
  id: PropTypes.string.isRequired,
  multiline: PropTypes.bool,
  errorText: PropTypes.string,
  placeholder: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  endAdornment: PropTypes.node,
  onChange: PropTypes.func.isRequired,
  items: PropTypes.array,
  sx: PropTypes.object,
};

export default function EasyTextEditField({
  disabled = false,
  error = false,
  type = "text",
  errorText = null,
  placeholder = null,
  label= null,
  endAdornment,
  multiline = false,
  id,
  password = false,
  onChange,
  icon,
  value = "",
  items = [],
  sx,
}) {
  const [showPassword, setShowPassword] = useState(false);
  const appStyle = coreStyles();
  const ado = password ? (
    <InputAdornment
      onClick={() => setShowPassword(!showPassword)}
      position="end"
    >
      <IconButton edge="center">
        <Icon
          children={showPassword ? "visibility" : "visibility_off"}
          className={appStyle.input_icons}
        />
      </IconButton>
    </InputAdornment>
  ) : (
    endAdornment
  );
  return (
    <TextField
        select={items.length > 0}
        placeholder={placeholder}
        type={password ? (showPassword ? "text" : "password") : type}
        error={error}
        disabled={disabled}
        inputlabelprops={{ shrink: true }}
        className={appStyle.inputs}
        value={value}
        label={label}
        multiline={multiline}
        rows={multiline ? 8: 1}
        helperText={error && errorText ? errorText: null}
        onChange={(event) => onChange(event.target.value, id)}
        InputProps={{
            startAdornment: icon && (<InputAdornment position="start">
            <IconButton edge="start">
              <Icon children={icon} className={appStyle.input_icons} />
            </IconButton>
          </InputAdornment>),
          endAdornment: ado
          }}
        >
          {items.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>
  );
}
