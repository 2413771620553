import {
  Grid,
  Typography,
  FormControlLabel,
  FormControl,
  Select,
  Checkbox,
  MenuItem,
  InputLabel
} from "@mui/material";
import {
  setTitle,
} from "../../utils/util";
import { useState, useEffect } from "react";
import { AppRoutes } from "../../router/routes";
import { getParams, navigate, preventIfNotAdmin } from "../../utils/route-util";
import EasyTextEdit from "../component/text-edit";
import EditPage from "../component/edit-page";
import { executeGetRequest, executePatchRequest, executePostRequest } from "../../utils/http-util";
import { cleanObject, isError, validateEmail, validateFields, validateName, validatePhone } from "../../utils/validation-util";
import { coreStyles } from "../../theme/style";
import { UserRole } from "../../utils/session-util";

const userRef = {
  firstName: "",
  lastName: "",
  emailAddress: "",
  phoneNumber: "",
  role: "MERCHANT",
  mfaEnabled: false,
  status: "CREATED",
  acceptedTerms: true
};

const validationRef = {
  firstName: { error: true, touched: false },
  lastName: { error: true, touched: false },
  emailAddress: { error: true, touched: false },
  phoneNumber: { error: true, touched: false },
};

export const UserEdit = () => {
  preventIfNotAdmin();
  const { uuid } = getParams();
  const [user, setUser] = useState(userRef);
  const [validation, setValidation] = useState(validationRef);
  const [validated, setValidated] = useState(false);
  const appStyle = coreStyles();
  const [status, setStatus] = useState({ error: false, message: null ,loading: false});

  useEffect(() => {
    if (uuid) {
      executeGetRequest(
        `users?uuid=${uuid}`,
        (loading) => setStatus({ ...{ loading: loading } })
      ).then(_user => {
        if (_user) setUser({ ..._user });
      });
    }
    setTitle(uuid ? "Edit User" : "Create user account")
    return () => setUser(userRef);
    // eslint-disable-next-line
  }, [uuid]);

  const createUpdate = () => {
    if (uuid) {
      executePatchRequest(
        `users/${uuid}`,
        cleanObject({ ...user }),
        (loading) => setStatus({ ...{ loading: loading } })
      ).then(result => setStatus({
        ...{
          error: result.error,
          message: !result.error ? "User information updated successfully" : result.message,
        },
      }));
    } else {
      executePostRequest(
        `users`,
        cleanObject({ ...user }),
        (loading) => setStatus({ ...{ loading: loading } })
      ).then(response => {
        setStatus({
          ...{
            error: response.error,
            message: !response.error ? "User added successfully" : response.message,
          },
        });
      });
    }

  };

  const onValueChange = (value, tag) => {
    user[tag] = value;

    if (tag === "firstName") {
      validation.firstName = { error: !validateName(value), touched: true };
    }
    if (tag === "lastName") {
      validation.lastName = { error: !validateName(value), touched: true };
    }

    if (tag.includes("email")) {
      validation.emailAddress = { error: !validateEmail(value), touched: true };
    }

    if (tag.includes("phone")) {
      validation.phoneNumber = { error: !validatePhone(value), touched: true };
    }
    setUser({ ...user });
    setValidation({ ...validation });
    setValidated(validateFields(validation) && user.acceptedTerms);
  };

  return (
    <EditPage
      title={uuid ? `Edit ${user.firstName}'s information` : "Add new user"}
      status={status}
      onSave={() => createUpdate()}
      validated={validated && !status.loading}
      onBack={() => navigate(`${AppRoutes.dashboard.users}`)}
      onFinish={() => {
        setStatus({ ...{ loading: false, message: null, error: false } });
        if (!status.error) {
          setUser({ ...userRef });
          navigate(`${AppRoutes.dashboard.users}`);
        }
      }}
    >

      <Grid item xs={12} md={6}>
        <EasyTextEdit
          placeholder="First name"
          type="text"
          id="firstName"
          errorText="Invalid first name"
          error={isError(validation.firstName)}
          disabled={status.loading}
          value={user.firstName}
          icon="person"
          onChange={onValueChange}
        />
      </Grid>

      <Grid item xs={12} md={6}>
        <EasyTextEdit
          placeholder="Last name"
          type="text"
          id="lastName"
          errorText="Invalid last name"
          error={isError(validation.lastName)}
          disabled={status.loading}
          value={user.lastName}
          icon="person"
          onChange={onValueChange}
        />
      </Grid>

      <Grid item xs={12} md={6}>
        <EasyTextEdit
          placeholder="Email address"
          type="email"
          id="emailAddress"
          errorText="Invalid email address"
          error={isError(validation.emailAddress)}
          disabled={status.loading}
          value={user.emailAddress}
          icon="alternate_email"
          onChange={onValueChange}
        />
      </Grid>

      <Grid item xs={12} md={6}>
        <EasyTextEdit
          placeholder="Phone number"
          type="phone"
          id="phoneNumber"
          errorText="Invalid phone number"
          error={isError(validation.phoneNumber)}
          disabled={status.loading}
          value={user.phoneNumber}
          icon="phone"
          onChange={onValueChange}
        />
      </Grid>

      <Grid item xs={12} md={6}>
        <FormControl fullWidth>
          <InputLabel id="user-role-label">Role</InputLabel>
          <Select
            labelId="user-role-label"
            id="user_role"
            value={user.role}
            label="Role"
            onChange={(event) => onValueChange(event.target.value, "role")}
          >
            {[UserRole.ADMIN, UserRole.MERCHANT].map(role => {
              return (<MenuItem value={role}>{role}</MenuItem>)
            })}
          </Select>
        </FormControl>
      </Grid>
      
      <Grid item xs={12} md={6}>
        <FormControlLabel
          sx={{ mt: 1 }}
          checked={user.mfaEnabled}
          onChange={(event) =>
            onValueChange(event.target.checked, "mfaEnabled")
          }
          control={<Checkbox />}
          label={
            <Typography className={appStyle.inputs} variant="body2">
              Enable Multifactor Authentication <strong>(MFA)</strong>
            </Typography>
          }
        />
      </Grid>



    </EditPage>
  );
};
