import {
  Box,
  Grid,
  Card,
  Tabs,
  Tab
} from "@mui/material";
import Markdown from 'react-markdown'
import { useState, useEffect } from "react";
import { coreStyles } from "../../theme/style";
import { styled } from '@mui/material/styles'
import Logo from "../component/logo";
import { LegalConstants } from "../../utils/legal-util";

const AntTabs = styled(Tabs)({
  borderBottom: '1px solid #e8e8e8',
  '& .MuiTabs-indicator': {
    backgroundColor: '#1890ff',
  },
});

const AntTab = styled((props) => <Tab disableRipple {...props} />)(({ theme }) => ({
  textTransform: 'none',
  minWidth: 0,
  [theme.breakpoints.up('sm')]: {
    minWidth: 0,
  },
  fontWeight: theme.typography.fontWeightRegular,
  marginRight: theme.spacing(1),
  fontSize:"1.3em",
  color: '#2065D1',
  fontFamily: [
    '-apple-system',
    'BlinkMacSystemFont',
    '"Segoe UI"',
    'Roboto',
    '"Helvetica Neue"',
    'Arial',
    'sans-serif',
    '"Apple Color Emoji"',
    '"Segoe UI Emoji"',
    '"Segoe UI Symbol"',
  ].join(','),
  '&:hover': {
    color: '#061B64',
    opacity: 1,
  },
  '&.Mui-selected': {
    color: '#061B64',
    fontWeight: theme.typography.fontWeightMedium,
  },
  '&.Mui-focusVisible': {
    backgroundColor: '#d1eaff',
  },
}));

const LegalInfo = () => {
  const appStyle = coreStyles();

  const [title, setTitle] = useState(LegalConstants.titles[0]);
  const [value, setValue] = useState(0);
  const [content, setContent] = useState(LegalConstants.contents[0]);

  const handleChange = (_, selected) => {
    setTitle(LegalConstants.titles[selected]);
    setContent(LegalConstants.contents[selected]);
    setValue(selected)
  };

  useEffect(() => {
    setTitle(`EasyPay ${title}`)
    // eslint-disable-next-line
  }, [title]);

  return (
    <Grid
      container
      className={appStyle.container}
      justifyContent="center"
      elevation={4}
      alignItems="center"
    >
      <Grid item textAlign="center" xs={12}>
        <Logo image="easypay-logo-white.svg" />
      </Grid>
      <Grid item xs={12} md={8}>
        <Card className={appStyle.loginCard}>

          <Grid container spacing={2}>
            <Box sx={{ width: '100%' }}>
              <Box sx={{ bgcolor: '#fff' }}>
                <AntTabs value={value} onChange={handleChange} aria-label="">
                  <AntTab label="Terms & Conditions" />
                  <AntTab label="Privacy Policies" />
                </AntTabs>
                <Box sx={{ p: 3 }} />
              </Box>
              <Box>
                <Markdown mb={4}>
                  {content}
                </Markdown>
                <Box sx={{ p: 3 }} />
              </Box>
            </Box>
          </Grid>
        </Card>
      </Grid>
    </Grid>
  );
};

export default LegalInfo;
