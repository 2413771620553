import { Typography, Link, Grid, Card } from "@mui/material";
import { useState, useEffect } from "react";
import LoadingButton from "../component/button";
import Logo from "../component/logo";
import Status from "../component/status";
import EasyTextEdit from "../component/text-edit";
import { Constants } from "../../utils/constants";
import { coreStyles } from "../../theme/style";
import {
  noEmptyFields,
  validatePassword,
  isError,
  cleanObject,
} from "../../utils/validation-util";
import { AppRoutes } from "../../router/routes";
import {
  getParams,
  navigate,
  preventIfNoValidToken,
} from "../../utils/route-util";
import { executePostRequest } from "../../utils/http-util";
import { decodeBase64Token } from "../../utils/session-util";
import { setTitle } from "../../utils/util";

const label = "Reset Password";

const authRef = {
  password: "",
  password_conf: "",
};

const validationRef = {
  password: { error: true, touched: false },
  password_conf: { error: true, touched: false },
};

export const AuthResetPassword = () => {
  const appStyle = coreStyles();
  const [onProcessing, setonProcessing] = useState(false);
  const [btnLabel, setLabel] = useState(label);
  const { token, verifier } = getParams();
  const [level, setLevel] = useState("");
  const [authProps, setAuthProps] = useState(authRef);
  const [validation, setValidation] = useState(validationRef);
  const [validated, setValidated] = useState(false);
  const [status, setStatus] = useState({ error: false, message: null });

  const handleSubmit = (event) => {
    event.preventDefault();
    executePostRequest(
      Constants.ENDPOINTS.AUTHS.CHANGE_PASSWORD,
      cleanObject({ ...authProps, password_conf: undefined, verifier }),
      (loading) => {
        setLabel("");
        setonProcessing(loading);
      },
      { authorization: decodeBase64Token(token) }
    ).then((result) => {
      setLabel(label);
      setStatus({
        ...{
          error: result.error,
          message: result.message,
        },
      });
    });
  };

  const onValueChanged = (value, isConfirm) => {
    if (isConfirm) {
      authProps["password_conf"] = value;
      validation.password_conf = {
        error: authProps.password !== authProps.password_conf,
        touched: true,
      };
    } else {
      const strn = validatePassword(value);
      authProps["password"] = value;
      setLevel(strn);
      validation.password = { error: strn.level < 75, touched: true };
    }
    setAuthProps({ ...authProps });
    setValidation({ ...validation });
    setValidated(noEmptyFields(validation));
  };

  useEffect(() => preventIfNoValidToken(), []);

  useEffect(() => {
    // eslint-disable-next-line
    setTitle("Reset Password")
  }, [btnLabel, onProcessing]);

  return (
    <Grid
      container
      className={appStyle.container}
      justifyContent="center"
      elevation={4}
      alignItems="center"
    >
      <Grid item textAlign="center" xs={12}>
        <Logo image="easypay-logo-white.svg" />
      </Grid>
      <Grid item xs={12} md={5}>
        <Card className={appStyle.loginCard}>
          <Typography
            variant="h2"
            color="primary.darker"
            align="center"
            mb={4}
            className={appStyle.title}
          >
            Reset Password
          </Typography>
          <Typography
            align="center"
            variant="body1"
            mb={4}
            className={appStyle.sub_title}
          >
            Please enter your new password
          </Typography>

          <Grid container spacing={4}>
            {status && status.message && (
              <Grid item xs={12} mb={4}>
                <Status
                  error={status.error}
                  timeout={5000}
                  message={status.message}
                  onClose={() => {
                    if (status.error) {
                      setStatus({ ...{ message: null } });
                    } else {
                      navigate(AppRoutes.auth.login);
                    }
                  }}
                />
              </Grid>
            )}
            <Grid item xs={12}>
              <EasyTextEdit
                placeholder="New Password"
                type="password"
                id="password"
                errorText={`Password is ${level.label}`}
                error={isError(validation.password)}
                disabled={onProcessing}
                value={authProps.password}
                icon="password"
                password={true}
                onChange={(value, _) => onValueChanged(value, false)}
              />
            </Grid>

            <Grid item xs={12}>
              <EasyTextEdit
                placeholder="Confirm New Password"
                type="password"
                id="password_conf"
                errorText={"Password doesn't match"}
                error={isError(validation.password_conf)}
                disabled={onProcessing}
                icon="password"
                value={authProps.password_conf}
                password={true}
                onChange={(value, _) => onValueChanged(value, true)}
              />
            </Grid>

            <Grid item xs={12}>
              <LoadingButton
                onClick={handleSubmit}
                loading={onProcessing}
                disabled={!validated || (!status.error && status.message)}
                label={btnLabel}
              />
            </Grid>

            <Grid item xs={12}>
              <p align="center">
                <Link
                  href={AppRoutes.auth.login}
                  variant="body2"
                  className={appStyle.links}
                >
                  &nbsp;&nbsp;Go back to Login
                </Link>
              </p>
            </Grid>
          </Grid>
        </Card>
      </Grid>
    </Grid>
  );
};
