import { AMQPWebSocketClient } from "./amqp-websocket-client.mjs";


export const getLavinMqConnection = async () => {
    const connectionParams = {
        vhost: process.env.REACT_APP_RPC_VHOST,
        username: process.env.REACT_APP_RPC_USERNAME,
        password: process.env.REACT_APP_RPC_PASSWORD,
        url: process.env.REACT_APP_RPC_URL
    };
    const client =  new AMQPWebSocketClient(connectionParams);
    return await client.connect();
}