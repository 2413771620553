import PropTypes from "prop-types";
import { Button, CircularProgress, Stack } from "@mui/material";
import { coreStyles } from "../../theme/style";
import { useSelector } from "react-redux";
import { useEffect } from "react";

LoadingButton.propTypes = {
  disabled: PropTypes.bool,
  reactToEnter: PropTypes.bool,
  loading: PropTypes.bool.isRequired,
  label: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  sx: PropTypes.object,
};

export default function LoadingButton({ reactToEnter = true, loading = false, disabled = false, label, onClick, sx }) {
  const appStyle = coreStyles();
  const { isEnter } = useSelector((state) => state.keyPress);

  useEffect(() => {
    if (isEnter && reactToEnter && !disabled){
      onClick && onClick({
        preventDefault: () => {}
      });
    }
    // eslint-disable-next-line
  }, [isEnter])

  return (
    <Button
      type="submit"
      fullWidth
      variant="contained"
      color="primary"
      id="someId"
      onClick={onClick}
      disabled={disabled || loading}
      className={appStyle.loginButton}
    >
      <Stack direction="row">
        {loading && <CircularProgress color="primary" />}
        {label}
      </Stack>
    </Button>
  );
}
